import React from 'react';
import styled from '@emotion/styled/macro';
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import theme from '../utils/theme';
import SearchBar from './SearchBar';
import AvatarButton from './AvatarButton';
import { Link } from 'react-router-dom';
import IconButton from '../components/IconButton';
import { mdiArrowLeft, mdiLogout, mdiGit, mdiInformationOutline, mdiMessageOutline } from '@mdi/js';
import Color from 'color';



import Icon from '@mdi/react';


const HeaderDiv = styled.header`
  grid-area: 1 / 1 / 2 / 2;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 1px, rgba(0, 0, 0, 0.25) 0px 8px 16px -4px;
  background-color: ${theme.color.background};
  width: 100%;
  height: 8.4rem;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 250ms ease 0s;
  transform: translateY(0px);
`;
const TopRow = styled.div`
  width: 100%;
  height: 4.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.5rem;
`;
const ProfileSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border-left: 1px solid ${theme.color.border};
  padding: 0 0 0 1.5rem;
  margin: 0 0 0 1.5rem;
`;
const NavigationSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border-right: 1px solid ${theme.color.border};
  padding: 0 1.5rem 0 0;
  margin: 0 1.5rem 0 0;
`;
const Title = styled.h1`
  font-family: ${theme.headlineFont};
  font-size: 1.7rem;
  font-weight: 600;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  letter-spacing: normal;
  color: ${theme.color.highEmphasis};
`;



const headerConfig = {
  avatar: {
    url: window.localStorage.getItem('gitlab-user') ? JSON.parse(window.localStorage.getItem('gitlab-user')).avatar_url : ''
  },
}


function Header(props) {
  const [topMenuVisible, setTopMenuVisible] = React.useState(false);
  // eslint-disable-next-line
  const headerInfo = {
    back : () => {
      const match = {
        '/contacts/person/': '/contacts/'
      }
      if (typeof window !== 'undefined') {
        const backUrl = match[window.location.pathname];
        if (backUrl === undefined) {
          return '';
        } else {
          return (
            <NavigationSection>
              <Link to={backUrl}>
                <IconButton>{mdiArrowLeft}</IconButton>
              </Link>
            </NavigationSection>
          );
        }
      }
    }
  }
  return (
    <>
      <HeaderDiv>
        <TopRow>
          {props.backButtonLink
            ? <NavigationSection>
                <Link to={props.backButtonLink}>
                  <IconButton>{mdiArrowLeft}</IconButton>
                </Link>
              </NavigationSection>
            : null
          }
          <SearchBar
            placeholder={props.searchPlaceholder}
            onChange={props.searchOnChange}
            onClick={props.searchOnClick}
            onKeyPress={props.searchOnKeyPress}
            value={props.searchValue}
            searchButtonOnClick={props.searchButtonOnClick}
          />
          <ProfileSection>
            <AvatarButton src={headerConfig.avatar.url} onClick={() => setTopMenuVisible(!topMenuVisible)} />
          </ProfileSection>
        </TopRow>
        <TopRow>
          <Title>{props.title}</Title>
          <div css={css`margin: 0 0 0 auto;`}>{props.children}</div>
        </TopRow>
      </HeaderDiv>

      <Menu id={`topmenu`} role={`menu`} topMenuVisible={topMenuVisible}>
        <MenuItemList>
          <MenuItem icon={mdiGit} href={`https://gitlab.com/troop370/website/-/tree/master/`}>Source code</MenuItem>
          <MenuItem icon={mdiInformationOutline} href={`https://troop370atlanta.org/contact-webmaster/`}>Get help</MenuItem>
          <MenuItem icon={mdiMessageOutline} href={`https://troop370atlanta.org/contact-webmaster/`}>Send feeback</MenuItem>
          <MenuItem type={`danger`} icon={mdiLogout} onClick={() => {
              window.localStorage.removeItem('state-campaignsStore');
              window.localStorage.removeItem('state-campaignsStore.nextURL');
              window.localStorage.removeItem('state-contactsStore');
              window.localStorage.removeItem('state.contacts-listMembershipMatch');
              window.localStorage.removeItem('tokens-constantContact');
              window.localStorage.removeItem('tokens-gitlab');
              window.localStorage.removeItem('gitlab-user');
              window.localStorage.removeItem('netlify-cms-user');
              window.location = '/';
            }}
          >Sign out</MenuItem>
        </MenuItemList>
      </Menu>
    </>
  )
}


const Menu = styled.div`
  display: ${({ topMenuVisible }) => (topMenuVisible ? "block" : "none")};;
  position: absolute;
  top: 2.5rem;
  right: 1.5rem;
  z-index: 9999;
  user-drag: none;
  user-select: none;
`;
const MenuItemList = styled.ul`
  background-color: ${theme.color.surface};
  border-radius: ${theme.radius};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 8px 2px, rgba(0, 0, 0, 0.25) 0px 16px 32px -8px;
  padding: 0.25rem;
  min-width: 200px;
  top: 32px;
`;
class MenuItem extends React.Component {
  render() {
    return (
      <MenuItemStyled type={this.props.type} href={this.props.href} onClick={this.props.onClick}>
        {this.props.icon 
          ? <Icon path={this.props.icon} title={this.props.icon} size={0.8336} css={css`margin-right: 0.75rem; min-width: 1.2504rem`} />
          : null
        }
        {this.props.children}
      </MenuItemStyled>
    )
  }
}
const MenuItemStyled = styled.a`
  font-family: ${theme.headlineFont};
  padding: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  transition: all 200ms ease 0s;
  display: flex;
  align-items: center;
  border: none;
  border-radius: ${theme.radius};
  color: ${({ type }) =>
    type === 'danger'
      ? theme.color.danger[300]
      : type === 'success'
      ? theme.color.success[300]
      : theme.color.highEmphasis};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  &:hover, &:focus {
    background-color: ${({ type }) =>
      type === 'danger'
        ? Color(theme.color.danger[900]).alpha(0.2).string()
        : type === 'success'
        ? Color(theme.color.success[900]).alpha(0.2).string()
        : Color(theme.color.neutral[700]).alpha(0.2).string()};
  }
  &:active, &:active:focus {
    background-color: ${({ type }) =>
      type === 'danger'
        ? Color(theme.color.danger[900]).alpha(0.1).string()
        : type === 'success'
        ? Color(theme.color.success[900]).alpha(0.1).string()
        : Color(theme.color.neutral[700]).alpha(0.1).string()};
  }
`;


export { Header as default, theme }; 