import React from 'react';

class uuidWidget extends React.Component {
  handleChange(e) {
    const prefix = (this.props.field.get('prefix') !== undefined) ? `${this.props.field.get('prefix')}-` : '';
    const suffix = (this.props.field.get('suffix') !== undefined) ? `-${this.props.field.get('suffix')}` : '';
    this.props.onChange(`${prefix}${this.uuidv4()}${suffix}`);
  }
  uuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      // eslint-disable-next-line
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  render() {
    const value = this.props.value;
    if (value === undefined) {
      this.handleChange();
    }
    return (
      <input
      id={this.props.forID}
      className={this.props.classNameWrapper}
      disabled={'disabled'}
      type={'text'}
      value={value}
      style={{ color: 'rgb(205, 205, 205)' }}
      onChange={this.handleChange}
      />
    );
  }
}

class uuidWidgetPreview extends React.Component {
  render() {
    return (
      <p>
        <span style={{ fontWeight: 'bold' }} >{this.props.field.get('label') + ': '}</span>
        <span>{this.props.value}</span>
      </p>
    );
  }
}

export { uuidWidget };
export { uuidWidgetPreview };


