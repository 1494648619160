import React from 'react';
import styled from '@emotion/styled/macro';
import theme from '../utils/theme'
import Color from 'color';
import { withRouter } from 'react-router-dom';

const Button = styled.button`
  font-family: ${theme.headlineFont};
  font-size: 14px;
  padding: 0px 0.75rem;
  line-height: 2rem;
  height: 2rem;
  font-weight: 600;
  text-align: center;
  border-radius: ${theme.radius};
  border: 0px;
  outline: 0px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 200ms ease 0s;
  -webkit-tap-highlight-color: transparent;
  background-color: ${({ type }) => (
    type === 'success'
    ? Color(theme.color.success['900']).alpha(0.2).string()
    : (type === 'danger')
    ? Color(theme.color.danger['900']).alpha(0.2).string()
    : Color(theme.color.neutral['700']).alpha(0.2).string()
  )};
  color: ${({ type }) => (
    type === 'success'
    ? theme.color.success['200']
    : (type === 'danger')
    ? theme.color.danger['200']
    : theme.color.neutral['300']
  )};
  &:hover, &:focus {
    background-color: ${({ type }) => (
      type === 'success'
      ? Color(theme.color.success['900']).alpha(0.35).string()
      : (type === 'danger')
      ? Color(theme.color.danger['900']).alpha(0.35).string()
      : Color(theme.color.neutral['700']).alpha(0.35).string()
    )};
  }
  &:active, &:active:focus {
    background-color: ${({ type }) => (
      type === 'success'
      ? Color(theme.color.success['900']).alpha(0.1).string()
      : (type === 'danger')
      ? Color(theme.color.danger['900']).alpha(0.1).string()
      : Color(theme.color.neutral['700']).alpha(0.1).string()
    )};
  }
`

function _Button(props) {
  return (
    <Button style={props.style} onClick={
      props.url !== undefined
      ? () => window.location = props.url
      : props.to !== undefined
      ? () => props.history.push(props.to)
      : props.onClick
    } type={props.type}>{props.children}</Button>
  )
}

export default withRouter(_Button);