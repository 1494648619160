import React from 'react';
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import * as CMSPreviewUtils from '../../../utils/injectPreviewCSS';

import MainTable from '../email-components/version2/MainTable';
import BackgroundTable from '../email-components/version2/BackgroundTable';
import HeaderTable from '../email-components/version2/HeaderTable';
import ContainerTable from '../email-components/version2/ContainerTable';
import CardTable from '../email-components/version2/CardTable';
import { Content } from '../email-components/version2/Content';

class EmailStandalonePreview extends React.Component {
  componentDidUpdate() {
    CMSPreviewUtils.styleMardownButtons(`iframe`);
  }
  componentDidMount() {
    CMSPreviewUtils.styleMardownButtons(`iframe`);
  }
  render() {
    const previewV2 = <>
      <div css={css`
        display: flex;
        align-items: center;
        height: 30px;
        padding: 10px;
        position: sticky;
        top: 0;
        background: rgb(203 228 255 / 80%);
        backdrop-filter: blur(10px);
        z-index: 999;
      `}>
        <style>
          {`
            body {
              margin: 0;
            }
            :root {
              --scrollbar-background: hsl(0, 0%, 16%);
              --scrollbar-thumb: hsl(0, 0%, 34%);
              --scrollbar-thumb-hover: hsl(0, 0%, 39%);
              --scrollbar-thumb-active: hsl(0, 0%, 52%);
              --scrollbar-button-hover: hsl(0, 0%, 24%);
              --scrollbar-button-active: hsl(0, 0%, 37%);
            }
            ::-webkit-scrollbar {
              background: var(--scrollbar-background) !important;
              width: 17px !important;
            }
            ::-webkit-scrollbar-button {
              height: 17px !important;
              width: 17px !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
            }
            ::-webkit-scrollbar-button:single-button:vertical:decrement {
              background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="8" height="8" viewBox="0 0 1024 1024" style="fill:lightgray;"> <title/> <path d="M977.5 766.5l-465.5-465-465.5 465-45-45 510.5-511 510.5 511z"/> </svg>');
            }
            ::-webkit-scrollbar-button:single-button:vertical:increment {
              background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="8" height="8" viewBox="0 0 1024 1024" style="fill:lightgray;"> <title/> <path d="M969.5 233.5l45 45-502.5 502.5-502.5-502.5 45-45 457.5 457.5z"/> </svg>');
            }
            ::-webkit-scrollbar-thumb {
              background: var(--scrollbar-thumb) !important;
              border: none !important;
              border-left: 1px solid var(--scrollbar-background) !important;
              border-right: 1px solid var(--scrollbar-background) !important;
              border-radius: 0 !important;
            }
            ::-webkit-scrollbar-thumb:hover {
              background: var(--scrollbar-thumb-hover) !important;
            }
            ::-webkit-scrollbar-thumb:active {
              background: var(--scrollbar-thumb-active) !important;
            }
            ::-webkit-scrollbar-button:hover {
              background-color: var(--scrollbar-button-hover) !important;
            }
            ::-webkit-scrollbar-button:active {
              background-color: var(--scrollbar-button-active) !important;
            }
          `}
        </style>
        <input
          type={`checkbox`}
          id={`chooseEmailSendCheck`}
          name={`chooseEmailSendCheck`}
          css={css`
            height: 18px;
            width: 18px;
            margin: 0;
            &::before {
              content: "";
              box-shadow: inset 0 0 0 1px #b5b5b5;
              background-color: #fff;
              height: 18px;
              width: 18px;
              margin: 0;
              display: block;
            }
            &:hover::before,
            &:checked:hover {
              box-shadow: inset 0 0 0 1px #121212;
            }
            &:checked:hover::before {
              box-shadow: inset 0 0 0 2px #121212;
            }
            &:active::before,
            &:checked:active::before {
              box-shadow: inset 0 0 0 2px #787878;
              background-color: #787878;
            }
            &:checked::before {
              box-shadow: inset 0 0 0 2px #003f87;
              background-color: #003f87;
              background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDI0IiBoZWlnaHQ9IjEwMjQiIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiIGZpbGw9IiNmZmYiPjx0aXRsZS8+PGcgaWQ9Imljb21vb24taWdub3JlIi8+PHBhdGggZD0iTTg3My41IDIzMy41bDQ1IDQ1LTUzNC41IDUzNS0yNzguNS0yNzkgNDUtNDUgMjMzLjUgMjMzIDQ4OS41LTQ4OXoiLz48L3N2Zz4=);
              background-size: 18px;
            }
          `}
        />
        <label
          htmlFor={`chooseEmailSendCheck`}
          css={css`
            font-family: roboto, sans-serif;
            font-size: 14px;
            color: #121212;
            height: calc(18px + 4px);
            line-height: calc(18px + 4px);
            margin-left: 8px;
          `}
        >I want to send/schedule this email after I click publish.</label>
      </div>
      <html id={`email`} css={css`
        div {
          display: contents;
        }
      `}>
        <head>

        </head>
        <body css={css`margin: 0;`}>
          <BackgroundTable>
            <tr>
              <td align={`center`}>
                <MainTable>
                  <tr>
                    <td>
                      <HeaderTable>
                        <tr>
                          <td>
                            <ContainerTable>
                              <tr>
                                <td className={`noPaddingOutlook`}>
                                  <ContainerTable>
                                    <tr>
                                      <td align={`center`}>
                                        <img
                                          data-imagetype={`External`}
                                          src={`https://troop370atlanta.org/img/troop370-logo/logo_layout2_white_email.png`}
                                          alt={`Scouts BSA Troop 370`}
                                          width={200}
                                          border={0}
                                          hspace={0}
                                          vspace={0}
                                          css={css`display: block;`}
                                        />
                                      </td>
                                    </tr>
                                  </ContainerTable>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h1
                                    css={css`
                                      font-family: roboto, sans-serif;
                                      font-size: 24px;
                                      color: rgb(224, 224, 224);
                                      line-height: 1.2;
                                      font-weight: 500;
                                      letter-spacing: normal;
                                      margin: 5px 0px;
                                      text-align: center;
                                    `}
                                  >{this.props.entry.getIn(['data', 'title'])}</h1>
                                  <p
                                    css={css`
                                      font-family: roboto, sans-serif;
                                      font-size: 16px;
                                      color: rgb(224, 224, 224);
                                      line-height: 1;
                                      font-weight: 400;
                                      letter-spacing: normal;
                                      margin: 0px 0px 10px;
                                      text-align: center;
                                    `}
                                  >{this.props.entry.getIn(['data', 'date'])}</p>
                                </td>
                              </tr>
                            </ContainerTable>
                          </td>
                        </tr>
                      </HeaderTable>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <CardTable>
                          <tr>
                            <td>
                              <Content>{this.props.widgetFor('content')}</Content>
                            </td>
                          </tr>
                      </CardTable>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <CardTable>
                        <tr>
                          <td>
                            <ContainerTable>
                              <tr>
                                <td width={`100%`} align={`center`} valign={`top`}>
                                  <p css={css`
                                    font-family: roboto, sans-serif;
                                    font-size: 13px;
                                    line-height: 1.5;
                                    font-weight: 400;
                                    margin: 0;
                                  `}>
                                    <a href={`https://www.facebook.com/groups/126169727423188/`} css={css`color: #003f87`}>Join us on Facebook</a>
                                    <span> • </span>
                                    <a href={`https://www.instagram.com/bsatroop370/`} css={css`color: #003f87`}>Follow us on Instagram</a>
                                    <span> • </span>
                                    <a href={`https://troop370.smugmug.com/`} css={css`color: #003f87`}>View Pictures on SmugMug</a>
                                  </p>
                                </td>
                              </tr>
                            </ContainerTable>
                          </td>
                        </tr>
                      </CardTable>
                    </td>
                  </tr>
                </MainTable>
              </td>
            </tr>
          </BackgroundTable>
        </body>
      </html>
    </>;
    if (this.props.entry.getIn(['data', 'version']) === 'version2') {
      return <>
          {previewV2}
        </>;
    }
    else {
      return <p>There is no preview for this version of the weekly newsletter. Try selecting a different version.</p>;
    }
  }
}

export { EmailStandalonePreview };
