export function injectPreviewCSS(selector, frame) {
  const styleElements = document.querySelectorAll(selector); // identify all style elements
  const iframe = document.querySelector(frame); // identify iframe
  const head = iframe.contentWindow.document.querySelector(`#email head`) ? iframe.contentWindow.document.querySelector(`#email head`) : iframe.contentWindow.document.head;
  styleElements.forEach(element => {
    const clonedElement = element.cloneNode(true) // copy style element
    head.appendChild(clonedElement); // inject style element into iframe head
  })
}

export function styleMardownButtons(frame) {
  const iframe = document.querySelector(frame); // identify iframe
  const anchors = iframe.contentWindow.document.getElementsByTagName('a'); // get all anchors in document
  for (var i = 0; i < anchors.length; i++) {
    const buttonText = anchors[i].innerHTML; // get text of anchor
    const buttonTypeOutlined = buttonText.includes('.ob') // check if anchor includes the text '.ob'
    const buttonTypeRegular = buttonText.includes('.pb') // check if anchor includes the text '.b'
    if (buttonTypeOutlined === true) { // only do the following if the anchor includes the text '.ob'
      anchors[i].innerHTML = buttonText.replace('.ob','');
      anchors[i].style.cssText = 'margin-top: 0px; font-family: Roboto, sans-serif; font-size: 13px; font-weight: 700; line-height: 18px; letter-spacing: 0.0892857em; text-decoration: none; text-transform: uppercase; padding: 6px 8px; border: 1px solid rgb(128, 128, 128); color: rgb(0, 63, 135); margin-top: 10px; display: inline-flex;';
    }
    if (buttonTypeRegular === true) { // only do the following if the anchor includes the text '.b'
      anchors[i].classList.add('mdc-button', 'md'); 
      anchors[i].innerHTML = buttonText.replace('.pb',''); 
    }
  }
}
