import styled from '@emotion/styled/macro';
import theme from '../../utils/theme';

const FieldLabel = styled.label`
  color: ${theme.color.lowEmphasis};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.5px;
  display: block;
  line-height: 1rem;
  transition: all 0.2s ease 0s;
  margin-top: -0.25rem;
  margin-bottom: 0.25rem;
  font-family: ${theme.headlineFont};
  cursor: pointer;
`;

export { FieldLabel };
