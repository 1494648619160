/** @jsxFrag React.Fragment */
import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import theme from '../../utils/theme';
import { FieldInside, FieldLabel, ChildrenWrap, StyledInput } from './';

class TextInput extends React.Component {
  state = {
    value: this.props.value
  }
  handleKeyPress = (event) => {
    this.setState({
      value: event.target.value
    })
  }
  render() {
    return (
      <div css={css`
        position: relative;
        box-shadow: ${theme.color.border} 0px -1px 0px 0px inset;
        transition: box-shadow 0.2s ease 0s;
        width: 100%;
        &:focus-within {
          box-shadow: ${theme.color.primary['900']} 0px -2px 0px 0px inset;
        }
      `}>
        <FieldInside>
          <FieldLabel for={this.props.name}>{this.props.label}</FieldLabel>
          <ChildrenWrap>
            <StyledInput
              onChange={this.handleKeyPress}
              name={this.props.name}
              id={this.props.name}
              value={this.state.value}
              disabled={this.props.disabled}
            />
          </ChildrenWrap>
        </FieldInside>
      </div>
    )
  }
}

export { TextInput };
