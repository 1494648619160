import styled from '@emotion/styled/macro';

export const Title3 = styled.h2`
font-family: roboto, sans-serif;
font-size: 14.5px;
line-height: 1.2;
font-weight: 500;
letter-spacing: normal;
margin: 5px 0;
color: rgb(0, 0, 0) !important;
`;
