import React from 'react';
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import Color from 'color';
import moment from 'moment';
// utilities
import { tools as toolsCC, request as requestCC } from '../utils/constantContact';
import theme from '../utils/theme';
// components
import Header from '../components/Header';
import Button from '../components/Button';
import ButtonGroup from '../components/ButtonGroup';

const isTokenCC = toolsCC.checkToken();

export default class EmailsPage extends React.Component {
  state = {
    searchValue: '',
    campaignsStore: [],
    campaigns: [],
    campaignsNextUrl: [],
    campaignsCurrentPage: 0,
    campaignsLastLoadedPage: 0
  }
  constructor(props) {
    super(props);
    this.getCampaigns = this.getCampaigns.bind(this);
    this.campaignPageRequest = this.campaignPageRequest.bind(this);
    this.clearCampaignsStoreLocal = this.clearCampaignsStoreLocal.bind(this);
    this.refreshCampaignsStoreLocal = this.refreshCampaignsStoreLocal.bind(this);
  }
  // clear campaignsStore from localStorage
  clearCampaignsStoreLocal() {
    window.localStorage.removeItem('state-campaignsStore');
  }
  // refresh campaignsStore data
  refreshCampaignsStoreLocal() {
    this.clearCampaignsStoreLocal();
    this.setState({
      campaignsStore: [],
      campaigns: [],
      campaignsNextUrl: '',
      campaignsCurrentPage: 0,
      campaignsLastLoadedPage: 0
    })
    this.componentDidMount();
  }
  async campaignPageRequest(page) {
    if (page === 'firstLoad') {
      await this.getCampaigns(`/v3/emails?limit=10`);
      this.setState({
        campaigns: this.state.campaignsStore[0],
        campaignsCurrentPage: 0,
        campaignsLastLoadedPage: 0
      })
    }
    if (page === 'next') {
      console.log(`[Campaigns] Getting next page...`);
      if (this.state.campaignsLastLoadedPage < this.state.campaignsCurrentPage + 1) {
        console.log('[Campaigns] Requesting next page from constant contact...');
        await this.getCampaigns(this.state.campaignsNextUrl);
        this.setState({
          campaigns: this.state.campaignsStore[this.state.campaignsCurrentPage + 1],
          campaignsCurrentPage: this.state.campaignsCurrentPage + 1,
          campaignsLastLoadedPage: this.state.campaignsCurrentPage + 1
        });
      } else {
        this.setState({
          campaigns: this.state.campaignsStore[this.state.campaignsCurrentPage + 1],
          campaignsCurrentPage: this.state.campaignsCurrentPage + 1
        });
      }
      document.getElementById('main').scrollTop = 0; // scroll to top of page after clicking next
    }
    if (page === 'previous') {
      console.log(`[Campaigns] Getting previous page...`);
      this.setState({
        campaigns: this.state.campaignsStore[this.state.campaignsCurrentPage - 1],
        campaignsCurrentPage: this.state.campaignsCurrentPage - 1
      });
    }
  }
  async getCampaigns(url) {
    this.setState({
      campaignsLoading: true
    })
    await new requestCC(url).request // make an XMLHttpRequest with the configuration in requestCC 
    .then(async (data) => {
      let campaigns = data.data.campaigns; // save campaign collection data
      for (let index = 0; index < campaigns.length; index++) { // loop through each campaign in the collection
        const campaign_id = campaigns[index].campaign_id;
        await new requestCC(`/v3/emails/${campaign_id}`).request // request more data on the campaign
        .then(data => {
          campaigns[index].campaign_activities = data.data.campaign_activities; // add data on campaign activities for the camapign to the campaign collection data
        })
        .catch(console.log);
      }
      this.setState({
        campaignsStore: [...this.state.campaignsStore, campaigns], // add the batch of campaigns as an array to the existing batch of campaigns in state
        campaignsNextUrl: (data.data._links.next !== undefined) ? data.data._links.next.href : undefined, // save the url for the next collection of campaigns to state (and save as undefiend if no url is available)
        campaignsLoading: false, // tell state that all requested campaigns are loaded,
      });
    })
    .catch(console.log);
    window.localStorage.setItem('state-campaignsStore', JSON.stringify(this.state.campaignsStore));
    window.localStorage.setItem('state-campaignsStore.nextURL', this.state.campaignsNextUrl);
  }
  componentDidMount() {
    if (window.localStorage.getItem('state-campaignsStore') !== null) { // check if basic email compaign data is in localStorage before getting from Constant Contact
      this.setState({ // set state data with localStorage data
        campaignsStore: JSON.parse(window.localStorage.getItem('state-campaignsStore')),
        campaigns: JSON.parse(window.localStorage.getItem('state-campaignsStore'))[0],
        campaignsNextUrl: window.localStorage.getItem('state-campaignsStore.nextURL'),
        campaignsCurrentPage: 0,
        campaignsLoading: false,
        campaignsLastLoadedPage: JSON.parse(window.localStorage.getItem('state-campaignsStore')).length - 1
      });
    } else if (isTokenCC === true) {
      this.campaignPageRequest('firstLoad');
    } else {
      this.setState({
        campaignsLoading: 'failed'
      });
    }
  }
  render() {
    return (
      <>
        <Header
          searchPlaceholder={`Search emails on constantcontact.com`}
          searchOnKeyPress={(e)=>{if(e.key === 'Enter'){window.location = `https://campaign-ui.constantcontact.com/campaign/campaigns/list?search=${encodeURIComponent(e.target.value)}`}}} // when enter/return pressed on keyboard, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          searchButtonOnClick={(e)=>window.location = `https://campaign-ui.constantcontact.com/campaign/campaigns/list?search=${encodeURIComponent(e.target.previousSibling.value)}`} // when search button clicked, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          searchOnChange={(e) => {this.setState({ searchValue: e.target.value })}}
          title={`Recent Emails`}
          searchValue={this.state.searchValue}
        >
          <ButtonGroup>
            {isTokenCC === false ?
              <Button to={`/signin?account_type=Constant%20Contact&account_reason=to%20access%20contacts%20and%20emails&redirect_when_done=%2Femails%2F`}>Authenticate</Button>
              :
              <>
                {this.state.campaignsCurrentPage === 0 ? null : <Button onClick={() => this.campaignPageRequest('previous')}>Previous</Button>}
                <Button onClick={() => this.campaignPageRequest('next')}>Next</Button>
                <Button onClick={this.refreshCampaignsStoreLocal}>Refresh data</Button>
              </>
            }
          </ButtonGroup>
        </Header>
        <EmailCard
          campaigns={this.state.campaigns}
          loading={this.state.campaignsLoading}
        />
        <div css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 1.5rem 1.5rem 1.5rem;
          background-color: ${theme.color.background};
        `}>
          {this.state.campaignsLoading === false && isTokenCC === true ?
            <ButtonGroup>
              {this.state.campaignsCurrentPage === 0 ? null : <Button onClick={() => this.campaignPageRequest('previous')}>Previous</Button>}
              <Button onClick={() => this.campaignPageRequest('next')}>Next</Button>
            </ButtonGroup>
            : null
          }
        </div>
      </>
    );
  }
}

class EmailCard extends React.Component {
  statusEquivalency = {
    Draft: 'Draft',
    Scheduled: 'Scheduled',
    Executing: 'Sending',
    Done: 'Sent',
    Error: 'Error Sending',
    Removed: 'Removed'
  }
  typeEquivalency = {
    NEWSLETTER: 'CC Editor Newstter',
    CUSTOM_CODE_EMAIL: 'Custom Code Email'
  }
  render() {
    return (
      <div
        css={css`
          padding: 1.5rem;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(auto-fill, minmax(50px, 1fr));
          grid-gap: 0.75rem;
          background-color: ${theme.color.background};
          > a {
            background-color: ${theme.color.surface};
        `}
      >
        <AuthMessage>{this.props.loading === true ? 'Loading...' : isTokenCC === false ? 'You need to authenticate via Constant Contact.' : this.props.loading === false ? '' : 'Failed to load.'}</AuthMessage>
        {this.props.campaigns === undefined ? null : this.props.campaigns.map((campaign) => (
          <a id={campaign.campaign_id} key={campaign.campaign_id} href={campaign.campaign_activities !== undefined ? `https://em-ui.constantcontact.com/em-ui/em/page/em-ui/email#details/activity/${campaign.campaign_activities.find(id => id.role === 'primary_email').campaign_activity_id}` : ``} style={{ textDecoration: 'none' }} css={css`pointer-events: ${campaign.campaign_activities !== undefined ? 'unset' : 'none'};`}>
            <CamapignCardContainer>
              <CampaignThumbnail src={campaign.campaign_activities !== undefined ? `https://campaign-thumbnail.constantcontact.com/v1/customer/1102974473196/activity/${campaign.campaign_activities.find(id => id.role === 'primary_email').campaign_activity_id}/size/150x220/mtime/17418313bba` : ``} />
              <div>
                <CamapignHeading>{campaign.name}</CamapignHeading>
                <CampaignStatusRow>
                  <CampaignStatusSend status={campaign.current_status}>{this.statusEquivalency[campaign.current_status] !== undefined ? this.statusEquivalency[campaign.current_status] : campaign.current_status}</CampaignStatusSend> 
                  <span>{this.typeEquivalency[campaign.type] !== undefined ? ' - ' + this.typeEquivalency[campaign.type] : ' - ' + campaign.type}</span>
                </CampaignStatusRow>
                <CampaignDate>
                  Updated {moment(campaign.updated_at).format('DD MMM YYYY [at] h:MM A')}
                </CampaignDate>
              </div>
            </CamapignCardContainer>
          </a>
        ))}
      </div>
    );
  };
};

const AuthMessage = styled.div`
  font-family: ${theme.contentFont};
  font-size: 1rem;
  font-weight: 500;
  color: ${theme.color.mediumEmphasis};
  margin: 0;
  &:empty {
    display: none;
  }
`;

const CamapignCardContainer = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1.5rem;
  align-items: center;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 1px, rgba(0, 0, 0, 0.25) 0px 8px 16px -4px;
  transition-duration: 200ms;
  transition-property: background-color;
  text-decoration: none;
  &:hover, &:focus {
    background-color: ${Color(theme.color.highEmphasis).alpha(0.05).string()};}
  }
  &:active {
    background-color: ${Color(theme.color.highEmphasis).alpha(0.1).string()};}
  }
`;

const CampaignThumbnail = styled.img`
  width: 100%;
  min-height: 117px;
  object-fit: cover;
  object-position: 50% 0%;
  grid-column: 1 / 2;
  opacity: 0.7;
`;

const CamapignHeading = styled.h2`
  font-family: ${theme.headlineFont};
  font-size: 1.5rem;
  font-weight: 500;
  color: ${theme.color.mediumEmphasis};
  margin: 0;
`;

const CampaignStatusRow = styled.p`
  font-family: ${theme.contentFont};
  font-size: 0.875rem;
  font-weight: 500;
  color: ${theme.color.lowEmphasis};
  margin: 0;
`;

const CampaignStatusSend = styled.span`
  color: ${props => props.status === 'Done'
    ? theme.color.success[700]
    : props.status === 'Scheduled'
    ? theme.color.yellow[700]
    : props.status === 'Error'
    ? theme.color.danger[700]
    : 'inherit'
  };
`;

const CampaignDate = styled.p`
  font-family: ${theme.contentFont};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${theme.color.lowEmphasis};
  margin: 0;
`;
