import {JSO} from 'jso-2';
import axios from 'axios';
import moment from 'moment';

const options = {
	providerID: "constantContact",
	client_id: "19c7d77c-c162-4bbb-afaa-291d85338260",
	redirect_uri: `${window.location.origin}/signin/`, // The URL where you is redirected back, and where you perform run the callback() function.
	authorization: "https://authz.constantcontact.com/oauth2/default/v1/authorize",
	scopes: {
    request: ['account_read', 'campaign_data', "contact_data"]
  },
  default_lifetime: 7200000, // 2 hours (per constant contact spec)
  response_type: 'token',
  debug: true
};
const client = new JSO(options); // creates JSO client using the options from above
if (window.location.pathname.startsWith('/signin') === true) { // only do this if on a contacts page
  client.callback(window.location.href.replace(`/#/`, `/#`)); // checks URL for information from Constant Contacts and saves it in localStorage (currently replaceing `/#/` with `/#` because JSO doesn't know how to handle a / between the hash and the rest of the information) (NetlifyCMS adds a / after the first #)
}

class request {
  constructor(url, method, data) {
    this.request = axios({
      url: url,
      method: method,
      baseURL: 'https://api.cc.email',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${typeof window !== 'undefined' && localStorage.getItem('tokens-constantContact') ? JSON.parse(localStorage.getItem('tokens-constantContact'))[0].access_token : null}`
      },
      responseType: 'json',
      responseEncoding: 'utf8',
      data: data
    });
  }
}

const tools = {
  getToken : async () => {
    client.getToken(options)
      .then((token) => {
        console.log(`[Constant Contact Auth] Bearer token: ${token.access_token}`);
      })
      .catch((err) => {
        console.error(`[Constant Contact Auth] Error from passive loader`, err)
      })
  },
  wipeTokens : () => {
    client.wipeTokens();
    console.log(`[Constant Contact Auth] All tokens removed`);
  },
  checkToken : () => {
    const token = client.checkToken(options);
    if (token) {
      console.log(`[Constant Contact Auth] Found valid token: ${token.access_token}`);
      console.log(`[Constant Contact Auth] Token will expire at ${moment(token.expires)}`);
      return true;
    } else {
      tools.wipeTokens();
      window.localStorage.removeItem('state-campaignsStore')
      window.localStorage.removeItem('state-campaignsStore.nextURL')
      window.localStorage.removeItem('state-contactsStore')
      window.localStorage.removeItem('state.contacts-listMembershipMatch')
      return false;
    }
  }
}

export { request };
export { tools };
