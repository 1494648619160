import styled from '@emotion/styled/macro';

export const Content = styled.div`
font-family: roboto, sans-serif;
font-size: 14px;
line-height: 1.5;
font-weight: 400;
letter-spacing: 0.03125em:
color: #000000;
`;
