import color from './color';
const theme = {
  contentFont: 'Roboto, sans-serif',
  headlineFont: 'IBM Plex Sans, Roboto, sans-serif',
  radius: '2px',
  backgroundColor: '#181818',
  onBackground: '#e0e0e0',
  surfaceColor: '#1c1c1c',
  color: {
    ...color,
    text: color.neutral['100'],
    label: color.neutral['500'],
    alternateBackground: color.neutral['1600'],
    background: color.neutral['1600'],
    surface: color.neutral['1500'],
    surfaceHighlight: color.neutral['1300'],
    elevatedSurface: color.neutral['1400'],
    elevatedSurfaceHighlight: color.neutral['1200'],
    border: color.neutral['1200'],
    borderHover: color.neutral['1000'],
    buttonPressed: color.neutral['1100'],
    button: color.neutral['1000'],
    buttonHover: color.neutral['900'],
    disabled: color.neutral['800'],
    lowEmphasis: color.neutral['500'],
    mediumEmphasis: color.neutral['300'],
    highEmphasis: color.neutral['100'],
  }
}
export { theme as default }