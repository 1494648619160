import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import theme from '../../utils/theme'

export default class TableHeaderRow extends React.Component {
  render() {
    return <div css={css`
      background-color: ${theme.color.background};
      display: flex;
      flex: 1 0 auto;
      min-width: 0px;
      align-items: flex-end;
      padding: 0.75rem 1.5rem;
      border-bottom: 1px solid ${theme.color.border};
      position: relative;
    `} role='row'>{this.props.children}</div>
  }
}