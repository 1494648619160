import styled from '@emotion/styled/macro';

export const Subtitle = styled.span`
  font-family: roboto, sans-serif;
  font-size: 12.5px;
  line-height: 1.35;
  font-weight: 400;
  letter-spacing: normal;
  margin: 0;
  opacity: 0.9;
  color: #000000;
  > p {
    margin: 2px 0 10px 0;
  }
`;
