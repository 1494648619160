import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';

export default class CardTable extends React.Component {
  render() {
    return (
      <table
        border={1}
        bordercolor={`#e0e0e0`}
        cellpadding={20}
        cellspacing={0}
        bgcolor={`#ffffff`}
        css={css`
          border-color: 1px solid rgba(222,222,222,0.7);
          background-color: #ffffff;
          padding: 20px;
          width: 100%;
          max-width: 640px;
          border-collapse: collapse;
        `}
      >
        {this.props.children}
      </table>
    );
  }
}
