import styled from '@emotion/styled/macro';

export const Title2 = styled.h2`
  font-family: roboto, sans-serif;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: normal;
  margin: 5px 0;
  text-transform: uppercase;
  color: rgb(0, 0, 0) !important;
`;
