import React from 'react';
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import Header from '../components/Header';
import styled from '@emotion/styled/macro';
import Color from 'color';
import { default as theme } from '../utils/theme';
import ChartJS from 'chart.js';
import Button from '../components/Button';
import ButtonGroup from '../components/ButtonGroup';
import ActivityList from '../components/dashboard/ActivityList';
import { mdiPulse, mdiChartBoxOutline, mdiFileOutline, mdiPencilOutline, mdiInboxArrowUpOutline, mdiCheck, mdiClockOutline, mdiPartyPopper } from '@mdi/js';
import WorkflowStatusCard from '../components/dashboard/WorkflowStatusCard';
import { request as requestGL, tools as toolsGL } from '../utils/gitlab';

const isTokenGL = toolsGL.checkToken();

export default class Home extends React.Component {
  state = {
    searchValue: '', // set deafult
    workflowDraftCount: 0,
    workflowPendingReviewCount: 0,
    workflowPendingPublishCount: 0,
    workflowPublishedCount: 0,
    workflowPublishNextPage: 1
  }
  constructor(props) {
    super(props);
    this.getWorkflowStatus = this.getWorkflowStatus.bind(this);
    this.getContentFileCount = this.getContentFileCount.bind(this);
  }
  async getWorkflowStatus() {
    new requestGL(`/projects/troop370%2Fwebsite/merge_requests?state=opened&labels=netlify-cms%2Fdraft`).request
      .then(response => {
        this.setState({ workflowDraftCount: response.data.length })
      })
      .catch(console.log);
    new requestGL(`/projects/troop370%2Fwebsite/merge_requests?state=opened&labels=netlify-cms%2Fpending_review`).request
      .then(response => {
        this.setState({ workflowPendingReviewCount: response.data.length })
      })
      .catch(console.log);
    new requestGL(`/projects/troop370%2Fwebsite/merge_requests?state=opened&labels=netlify-cms%2Fpending_publish`).request
      .then(response => {
        this.setState({ workflowPendingPublishCount: response.data.length })
      })
      .catch(console.log);
    this.getContentFileCount();
  }
  async getContentFileCount() {
    do {
      await new requestGL(`/projects/troop370%2Fwebsite/repository/tree?path=content&recursive=true&per_page=100&page=${this.state.workflowPublishNextPage}`).request
        .then(response => {
          console.log(response);
          this.setState({
            workflowPublishedCount: this.state.workflowPublishedCount + response.data.length,
            workflowPublishNextPage: response.headers['x-next-page'] ? response.headers['x-next-page'] : undefined
          })
        })
        .catch(console.log);
    }
    while (this.state.workflowPublishNextPage !== undefined);
    console.log(this.state.workflowPublishNextPage)
  }
  componentDidMount() {
    if (isTokenGL) {
      this.getWorkflowStatus()
    }
  }
  render() {
    return (
      <>
        <Header
          searchPlaceholder={`Search pages, posts, forms, and emails`}
          searchOnKeyPress={(e)=>{if (e.key === 'Enter') {window.location = `/cms/#/search/${encodeURIComponent(e.target.value)}`}}} // when enter/return pressed on keyboard, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          searchButtonOnClick={(e)=>window.location = `/cms/#/search/${encodeURIComponent(e.target.previousSibling.value)}`} // when search button clicked, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          searchOnChange={(e) => {this.setState({ searchValue: e.target.value })}}
          title={`Troop 370 Content Portal`}
          searchValue={this.state.searchValue}
        >
        </Header>
        <div
          css={css`
            height: calc(100% - 8.4rem);
            overflow: auto;
            display: flow-root;
          `}
        >
          <MainGrid>
            <AnalyticsSection />
            <GridItem gridArea={`activity`}>
              <Head>
                <Icon path={mdiPulse} />
                Recent CMS Activity
              </Head>
              <ActivityList />
            </GridItem>
            <GridItem gridArea={`workflow`} leftBorder={'show'}>
              <Head>
                <Icon path={mdiChartBoxOutline} />
                Workflow
              </Head>
              <WorkflowStatusCard color={`neutral`} icon={mdiFileOutline} count={this.state.workflowPublishedCount + this.state.workflowDraftCount + this.state.workflowPendingReviewCount + this.state.workflowPendingPublishCount}>All Entries</WorkflowStatusCard>
              <WorkflowStatusCard color={`pink`} icon={mdiPencilOutline} count={this.state.workflowDraftCount}>Drafts</WorkflowStatusCard>
              <WorkflowStatusCard color={`yellow`} icon={mdiInboxArrowUpOutline} count={this.state.workflowPendingReviewCount}>In Review</WorkflowStatusCard>
              <WorkflowStatusCard color={`orange`} icon={mdiClockOutline} count={this.state.workflowPendingPublishCount}>Ready</WorkflowStatusCard>
              <WorkflowStatusCard color={`turquoise`} icon={mdiCheck} count={this.state.workflowPublishedCount}>Published</WorkflowStatusCard>
            </GridItem>
            <GridItem gridArea={`row-3`}>
              <Head>
                <Icon path={mdiPartyPopper} />
                Welcome to the Content Portal Beta
              </Head>
              <ConstructionExcuse>The dashboard is still under construction, but the Content Manager, Email Overview, and Contact Manager are already available. The search bars are also fully functional. Access these tools by using the sidebar on the left.</ConstructionExcuse>
            </GridItem>
          </MainGrid>
        </div>
      </>
    );
  }
}

const Head = styled.h2`
  font-size: 1.125rem;
  font-weight: 600;
  font-family: ${theme.headlineFont};
  color: ${theme.color.highEmphasis};
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ConstructionExcuse = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  font-family: ${theme.contentFont};
  color: ${theme.color.mediumEmphasis};
`;

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 400px 1fr;
  grid-template-areas:
    "analaytics analaytics"
    "activity workflow"
    "row-3 row-3"
    "row-4 row-4";
  }
`;

class GridItem extends React.Component {
  render() {
    return (
      <div css={css`
        border-bottom: 1px solid ${Color(theme.color.neutral[900]).alpha(0.3).string()};
        padding: 1.5rem;
        grid-area: ${this.props.gridArea};
        border-left: ${
          this.props.leftBorder === 'show'
          ? `1px solid ${Color(theme.color.neutral[900]).alpha(0.3).string()}`
          : `none`
        };
      `}>
        {this.props.children}
      </div>
    );
  }
}

class Icon extends React.Component {
  render() {
    return (
      <svg viewBox={`0 0 24 24`} style={{height: 20, marginRight: '0.625rem'}}><path d={this.props.path} style={{fill: "currentcolor"}}></path></svg>
    )
  }
}


















































class AnalyticsSection extends React.Component {
  componentDidMount() {
    const ctx = document.getElementById('analyticsChart').getContext('2d');

    let gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'green');
    gradient.addColorStop(1, 'pink');
    ctx.fillStyle = gradient;
    ctx.fillRect(10, 10, 200, 100);

    new ChartJS(ctx, {
      // The type of chart we want to create
      type: 'line',
  
      // The data for our dataset
      data: {
          labels: ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', ''],
          datasets: [{
              label: 'My First dataset',
              backgroundColor: Color(theme.color.primary[900]).alpha(0.1).string(),
              borderColor: theme.color.primary[900],
              data: [0, 10, 5, 2, 20, 26, 35, 30, 38, 20, 28, 23, 27, 26]
          }]
      },
  
      // Configuration options go here
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: 0
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            position: 'top',
            gridLines: {
              color: Color(theme.color.neutral[900]).alpha(0.3).string(), // line color
              drawBorder: false // no border between axis and axis labels
            },
            ticks: {
              fontFamily: theme.headlineFont,
              fontColor: theme.color.lowEmphasis,
              fontSize: 12,
              fontStyle: 'normal',
              padding: 16
            }
          }],
          yAxes: [{
            gridLines: {
              display: true, // hide lines
              drawBorder: false // no border between axis and axis labels
            },
            ticks: {
              fontFamily: theme.headlineFont,
              fontColor: theme.color.lowEmphasis,
              fontSize: 12,
              fontStyle: 'normal',
              padding: -32,
              lineHeight: 0
            }
          }]
        }
      }
    });
  }
  render() {
    return (
      <div css={css`
        grid-area: 1 / 1 / 2 / 3;
        border-bottom: 1px solid ${Color(theme.color.neutral[900]).alpha(0.3).string()};
      `}>
        <div css={css`
          height: 4.2rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 1.5rem;
        `}>
          <Head>Site Analytics</Head>
          <div css={css`
            margin: 0 0 0 auto;
          `}>
            <ButtonGroup>
              <Button>Sort</Button>
              <Button>Year</Button>
            </ButtonGroup>
          </div>
        </div>
        <div css={css`
          height: calc(100% - 4.2rem);
        `}>
          <canvas id="analyticsChart"></canvas>
        </div>
      </div>
    )
  }
}
