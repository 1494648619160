import styled from '@emotion/styled/macro';
import theme from '../../utils/theme';

const StyledInput = styled.input`
  color: ${theme.color.highEmphasis};
  background: none;
  border: none;
  outline: none;
  width: calc(100% + 32px);
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 1rem;
  caret-color: ${theme.color.primary[900]};
  margin: -2rem -1rem -1rem;
  padding: 2rem 1rem 1rem;
`;

export { StyledInput };
