import styled from '@emotion/styled/macro';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import Icon from '@mdi/react'
import theme from '../utils/theme'
import Color from 'color';

const Button = styled.button`
  padding: 0.25rem;
  height: 2rem;
  width: 2rem;
  border-radius: ${theme.radius};
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease 0s;
  background-color: transparent;
  color: rgb(211, 214, 223);
  -webkit-tap-highlight-color: transparent;
  &:hover, &:focus {
    background-color: ${Color(theme.color.highEmphasis).alpha(0.05).string()};
  }
  &:active, &:active:focus {
    background-color: ${Color(theme.color.highEmphasis).alpha(0.1).string()};
  }
`

function _IconButton(props) {
  return (
    <Button onClick={ props.url !== undefined ? window.location = props.url : props.onClick}>
      <Icon path={props.children} title={`back`} size={0.8336} css={css`margin: 0.375rem; min-width: 1.2504rem`} />
    </Button>
  )
}

export { _IconButton as default }; 