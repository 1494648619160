import React from "react";
import styled from "@emotion/styled/macro";
import theme from "./utils/theme";
import { Route, withRouter } from "react-router-dom";
// page components
import Navbar from "./components/Navbar/Navbar";
// pages
import home from "./pages/home";
import cms from "./pages/cms";
import cristata_redirect from "./pages/cristata_redirect";
import emails from "./pages/emails";
import emails_send from "./pages/emails_send";
import contacts from "./pages/contacts";
import person from "./pages/contacts_person";
import signin from "./pages/signin";

const Content = styled("div")`
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: ${theme.color.background};
  color: ${theme.onBackground};
`;
const Main = styled("div")`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

class App extends React.Component {
  componentDidMount() {
    if (
      window.location.pathname.indexOf("/cms") !== 0 &&
      window.localStorage.getItem("tokens-gitlab") === null
    ) {
      this.props.history.push(
        encodeURI(
          `/signin?account_type=GitLab&account_reason=to continue to Communication Portal&redirect_when_done=${window.location.pathname}`
        )
      );
    } else if (window.sessionStorage.getItem("emails.emailToSend") !== null) {
      this.props.history.push(`/emails/send/`);
    }
  }
  render() {
    return (
      <>
        <Content>
          <Navbar />
          <Main id={`main`}>
            <Route exact={true} path="/" component={home} />
            <Route path="/netlifycms" component={cms} />
            <Route path="/cms" component={cristata_redirect} />
            <Route path="/emails" exact={true} component={emails} />
            <Route path="/emails/send" component={emails_send} />
            <Route path="/contacts" exact={true} component={contacts} />
            <Route path="/contacts/person" component={person} />
            <Route path="/signin" component={signin} />
          </Main>
        </Content>
      </>
    );
  }
}

export default withRouter(App);
