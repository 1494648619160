import React from "react"
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';

class ButtonGroup extends React.Component {
  render() {
    return (
      <div css={css`display: flex; flex-direction: row; gap: 8px;`}>{this.props.children}</div>
    )
  }
}

export { ButtonGroup as default };
