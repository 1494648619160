/** @jsxFrag React.Fragment */
import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { withRouter } from 'react-router-dom';
// utilities
import { tools as toolsCC } from '../utils/constantContact';
import { tools as toolsGL, request as requestGL } from '../utils/gitlab';
import theme from '../utils/theme';
// components
import Button from '../components/Button';

class signin extends React.Component {
  state = {
    accountType: new URLSearchParams(window.location.search).get('account_type') || window.sessionStorage.getItem('signin.accountType.last') || 'GitLab',
    accountReason: new URLSearchParams(window.location.search).get('account_reason') || window.sessionStorage.getItem('signin.accountReason.last') || 'to continue to Content Portal',
    redirectWhenDone: new URLSearchParams(window.location.search).get('redirect_when_done') || window.sessionStorage.getItem('signin.redirectWhenDone.last') || '/'
  }
  componentDidMount() {
    window.sessionStorage.setItem('signin.accountType.last', this.state.accountType);
    window.sessionStorage.setItem('signin.accountReason.last', this.state.accountReason);
    if (this.state.redirectWhenDone === '/signin') this.state.redirectWhenDone = '/';
    if (this.state.redirectWhenDone === '/signin/') this.state.redirectWhenDone = '/';
    window.sessionStorage.setItem('signin.redirectWhenDone.last', this.state.redirectWhenDone);
    if (this.state.accountType === 'Constant Contact') {
      if (toolsCC.checkToken() === true) {
        this.props.history.push(this.state.redirectWhenDone);
      }
    }
    if (this.state.accountType === 'GitLab') {
      if (toolsGL.checkToken() === true) {
        new requestGL('/user', 'GET').request
        .then(response => {
          window.localStorage.setItem('gitlab-user', JSON.stringify(response.data));
          this.props.history.push(this.state.redirectWhenDone);
        })
        .catch(console.log);
        this.props.history.push(this.state.redirectWhenDone);
      }
    }
  }
  render() {
    return (
      <div css={css`
        height: 100%;
        width: 100%;
        background-color: ${theme.color.background};
        position: absolute;
        left: 0;
        z-index: 999;
      `}>
        <div css={css`
          position: absolute;
          top: calc((50vh - 48px) - (48px / 2));
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 300px;
          background-color: ${theme.color.surface};
          box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
          padding: 45px;
          box-sizing: content-box;
        `}>
          <div>
            <p css={css`
              font-family: ${theme.headlineFont};
              font-weight: 600;
              font-size: 1.5rem;
              line-height: 1rem;
              color: ${theme.color.primary[1000]};
              margin: 0;
              text-align: center;
              letter-spacing: .03125em;
            `}>Troop 370</p>
            <p css={css`
              font-family: ${theme.headlineFont};
              font-weight: 400;
              font-size: 1.5rem;
              line-height: 1.75rem;
              color: ${theme.color.mediumEmphasis};
              margin: 0 0 -2px;
              text-align: center;
              padding-top: 22px;
            `}>Sign in</p>
            <p css={css`
              font-family: ${theme.headlineFont};
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.5rem;
              color: ${theme.color.mediumEmphasis};
              margin: 0;
              text-align: center;
              padding-top: 8px;
            `}>{this.state.accountReason}</p>
          </div>
          <div css={css`padding: 20px 0;`}>
            <Button
              style={
                {
                  'width': '100%',
                  'height': '3rem'
                }
              }
              onClick={async () => {
                if (this.state.accountType === 'GitLab') {
                  toolsGL.getToken();
                }
                else if (this.state.accountType === 'Constant Contact') {
                  toolsCC.getToken();
                }
                else {
                  console.error('Authenication failed because it could not determine the account type to use when signing in')
                }
              }}
            >Sign in with {this.state.accountType}</Button>
          </div>
          <div>
            <p css={css`
              font-family: ${theme.contentFont};
              color: rgba(222,222,222,.7);
              font-size: 14px;
              line-height: 1.4286;
              margin: 0 0 10px 0;
            `}>Forgot your password? Request help from the Webmaster</p>
            <a css={css`
              font-family: ${theme.headlineFont};
              font-weight: 600;
              font-size: 14px;
              color: ${theme.color.success[1000]};
            `} href={`https://troop370atlanta.org/contact-webmaster`}>Contact Webmaster</a>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(signin);
