import React from 'react';
import styled from '@emotion/styled/macro';
// utilities
import { request as requestCC, tools as toolsCC } from '../utils/constantContact';
import theme from '../utils/theme';
// components
import Header from '../components/Header';
import Tag from '../components/Tag';
import Button from '../components/Button';
import ButtonGroup from '../components/ButtonGroup';
import { TableWrap, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyList, TableRow, TableCell } from '../components/Table/';

const isTokenCC = toolsCC.checkToken();

export default class ContactsPage extends React.Component {
  state = {
    contactsStore: [],
    contacts: [],
    contactsNextUrl: '',
    contactsLoading: false,
    scrollTop: 0,
    listMembershipMatch: {},
    searchValue: new URLSearchParams(window.location.search).get('search_query') // set deafult from URL parameter
  }
  constructor(props) {
    super(props);
    this.setContactsTableData = this.setContactsTableData.bind(this);
    this.clearContactsStoreLocal = this.clearContactsStoreLocal.bind(this);
    this.refreshContactsStoreLocal = this.refreshContactsStoreLocal.bind(this);
  }
  async setContactsTableData() {
    if (isTokenCC) {
      let url = `/v3/contacts?limit=500&include=phone_numbers,list_memberships&include_count=true`;
      do {
        this.setState({
          contactsLoading: true,
          //scrollTop: document.getElementById('main').scrollTop // save the scrollTop value to state
        })
        await new requestCC(url).request // make an XMLHttpRequest with the configuration in requestCC 
        .then(data => {
          this.setState({
            contactsStore: this.state.contactsStore.concat(data.data.contacts), // add the new contacts to the existing contacts array in state
            contactsNextUrl: (data.data._links.next !== undefined) ? data.data._links.next.href : undefined, // save the url for the next batch of contacts to state (and save as undefiend if no url is available)
            contactsLoading: false, // tell state that all requested contacts are loaded
          });
          this.setState({
            contacts: this.state.contactsStore
          })
          //document.getElementById('main').scrollTop = this.state.scrollTop; // set the scrollTop value to what it was before more contact rows were added
        })
        .catch(console.log);
        url = this.state.contactsNextUrl;
      }
      while (this.state.contactsNextUrl !== undefined)
      window.localStorage.setItem('state-contactsStore', JSON.stringify(this.state.contactsStore));
    }
  }
  async matchContactListsToNameInObject() {
    let object = {};
    await new requestCC('/v3/contact_lists?include_count=false').request
    .then(data => {
      data.data.lists.map(list =>
        object[list.list_id] = list.name
      )
    })
    .catch(console.log);
    this.setState({ listMembershipMatch: object })
    window.localStorage.setItem('state.contacts-listMembershipMatch', JSON.stringify(this.state.listMembershipMatch));
  }
  // clear contactsStore from localStorage
  clearContactsStoreLocal() {
    window.localStorage.removeItem('state-contactsStore');
    window.localStorage.removeItem('state.contacts-listMembershipMatch');
  }
  // refresh contactsStore data
  refreshContactsStoreLocal() {
    this.clearContactsStoreLocal();
    this.setState({
      contactsStore: [],
      contacts: [],
      contactsNextUrl: ''
    })
    this.componentDidMount();
  }
  componentDidMount() {
    if (window.localStorage.getItem('state.contacts-listMembershipMatch') !== null) { // check if object with lists and ids is in localStorage before getting from Constant Contact
      this.setState({ // set state data with localStorage data
        listMembershipMatch: JSON.parse(window.localStorage.getItem('state.contacts-listMembershipMatch'))
      })
    } else {
      this.matchContactListsToNameInObject();
    }
    if (window.localStorage.getItem('state-contactsStore') !== null) { // check if contact data is in localStorage before getting from Constant Contact
      this.setState({ // set state data with localStorage data
        contactsStore: JSON.parse(window.localStorage.getItem('state-contactsStore')),
        contacts: JSON.parse(window.localStorage.getItem('state-contactsStore')),
        contactsNextUrl: undefined
      });
    } else {
      this.setContactsTableData();
    }
  }
  handleKeyPress = (event, arrowButton) => {
    let value = arrowButton === true ? event.target.previousSibling.value : event.target.value;
    this.props.history.push(`/contacts/?search_query=${encodeURIComponent(value)}`);
    this.setState({
      searchValue: value
    })
    const contacts = this.state.contactsStore;
    let array = [];
    // first name
    const resultFirstName = contacts.filter(item => item.first_name ? item.first_name.includes(value) : null );
    array = array.concat(resultFirstName);
    // last name
    const resultLastName = contacts.filter(item => item.last_name ? item.last_name.includes(value) : null );
    array = array.concat(resultLastName);
    // email
    const resultEmail = contacts.filter(item => item.email_address ? item.email_address.address ? item.email_address.address.includes(value) : null : null );
    array = array.concat(resultEmail);

    array = Array.from(new Set(array)); // removes duplicates from the array
    this.setState({ contacts: array })
  }
  render() {
    return (
      <>
        <Header
          searchPlaceholder={`Search contacts`}
          searchOnKeyPress={(e)=>{if(e.key === 'Enter'){this.handleKeyPress(e)}}} // when enter/return pressed on keyboard, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          searchButtonOnClick={(e)=>{this.handleKeyPress(e, true)}} // when search button clicked, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          searchOnChange={(e) => {this.setState({ searchValue: e.target.value })}}
          title={`Scouting Contacts`}
          searchValue={this.state.searchValue}
        >
          <ButtonGroup>
            {isTokenCC === false 
              ? <Button to={`/signin?account_type=Constant%20Contact&account_reason=to%20access%20contacts%20and%20emails&redirect_when_done=%2Fcontacts%2F`}>Authenticate</Button>
              : <Button onClick={this.refreshContactsStoreLocal}>Refresh data</Button>
            }
          </ButtonGroup>
        </Header>
        <AuthMessage>{this.state.contactsLoading === true ? 'Loading...' : isTokenCC === false ? 'You need to authenticate via Constant Contact.' : this.state.contactsLoading === false ? '' : 'Failed to load.'}</AuthMessage>
        { this.state.contacts.length > 0 ? 
        <TableWrap>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>First Name</TableHeaderCell>
              <TableHeaderCell>Last Name</TableHeaderCell>
              <TableHeaderCell>Email</TableHeaderCell>
              <TableHeaderCell>Lists</TableHeaderCell>
              <TableHeaderCell>Subscription</TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            <TableBodyList>
              <ContactRow contacts={this.state.contacts} lists={this.state.listMembershipMatch}/>
            </TableBodyList>
          </TableBody>
        </TableWrap>
        : null }
      </>
    );
  }
}

const AuthMessage = styled.div`
  font-family: ${theme.contentFont};
  font-size: 1rem;
  font-weight: 500;
  color: ${theme.color.mediumEmphasis};
  margin: 0;
  padding: 1.5rem;
  &:empty {
    display: none;
  }
`;

class ContactRow extends React.Component {
  render() {
    return (
      <>
        {this.props.contacts.map((contact) => (
          <span key={contact.contact_id}>
            <TableRow url={`/contacts/person/?id=${contact.contact_id}`}>
              <TableCell>{contact.first_name}</TableCell>
              <TableCell>{contact.last_name}</TableCell>
              <TableCell>{contact.email_address !== undefined ? contact.email_address.address : ''}</TableCell>
              <TableCell>{contact.list_memberships.map(id => (<span key={Math.random()}><Tag color={`neutral`}>{this.props.lists[id]}</Tag></span>))}</TableCell>
              <TableCell>{contact.email_address !== undefined ? contact.email_address.permission_to_send : ''}</TableCell>
            </TableRow>
          </span>
        ))}
      </>
    );
  };
};
