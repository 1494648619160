import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import theme from '../../utils/theme'
import Color from 'color';
import { Link } from 'react-router-dom';

export default class TableRow extends React.Component {
  render() {
    return <Link to={this.props.url} css={css`
      display: flex;
      justify-content: stretch;
      min-height: 56px;
      padding: 0px 1.5rem;
      -webkit-box-align: center;
      align-items: center;
      background-color: transparent;
      border-bottom: 1px solid ${theme.color.border};
      transition-duration: 200ms;
      transition-property: background-color;
      text-decoration: none;
      &:hover, &:focus {
        color: ${theme.color.highEmphasis};
        background-color: ${Color(theme.color.highEmphasis).alpha(0.05).string()};}
      }
      &:active {
        color: ${theme.color.highEmphasis};
        background-color: ${Color(theme.color.highEmphasis).alpha(0.1).string()};}
      }
    `}>{this.props.children}</Link>
  }
}