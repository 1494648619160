import React from 'react';
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import * as CMSPreviewUtils from '../../../utils/injectPreviewCSS';

import MainTable from '../email-components/version2/MainTable';
import BackgroundTable from '../email-components/version2/BackgroundTable';
import CardTable from '../email-components/version2/CardTable';
import { Title2 } from '../email-components/version2/Title2';
import { Subtitle } from '../email-components/version2/Subtitle';
import { Content } from '../email-components/version2/Content';

class PostPreview extends React.Component {
  componentDidUpdate() {
    CMSPreviewUtils.styleMardownButtons(`iframe`);
  }
  componentDidMount() {
    CMSPreviewUtils.styleMardownButtons(`iframe`);
  }
  render() {
    return (
      <>
        <style>{`body{margin:0;}`}</style>
        <div id={`notice`}>
          <style>{`#notice{display:flex;align-items:center;height:50px;max-height:50px;box-sizing:border-box;padding:20px;position:sticky;top:0;background:rgb(203 228 255 / 80%);backdrop-filter:blur(10px);z-index: 999;}`}</style>
          <p css={css`
            font-family: roboto, sans-serif;
            font-size: 14px;
          `}>Some content may appear differently in the email.</p>
        </div>
        <div css={css`height: calc(100vh - 51px);`}>
          <BackgroundTable>
              <tr css={css`
                vertical-align: top;
                height: calc(100vh - 51px);
              `}>
                <td>
                  <MainTable>
                      <tr>
                        <td>
                          <CardTable>
                              <tr>
                                <td>
                                  <Title2>{this.props.entry.getIn(['data', 'title'])}</Title2>
                                  <Subtitle dangerouslySetInnerHTML={{__html: this.props.entry.getIn(['data', 'subtitle'])}} />
                                  <Content>{this.props.widgetFor('body')}</Content>
                                </td>
                              </tr>
                          </CardTable>
                        </td>
                      </tr>
                  </MainTable>
                </td>
              </tr>
          </BackgroundTable>
        </div>
      </>
    );
  }
}

export { PostPreview };
