import {JSO} from 'jso-2';
import axios from 'axios';
import moment from 'moment';
const options = {
	providerID: "gitlab",
	client_id: "5c5039b822028559fce9fed90c5b50cb14910cdba75b1314d1fc1d3b434ecd45",
	redirect_uri: `${window.location.origin}/signin/`, // The URL where you is redirected back, and where you perform run the callback() function.
	authorization: "https://gitlab.com/oauth/authorize",
  response_type: 'code',
  client_secret: process.env.REACT_APP_GITHUB_AUTH_SECRET,
  token: 'https://gitlab.com/oauth/token',
  debug: true
};
const client = new JSO(options); // creates JSO client using the options from above
if (window.location.pathname.startsWith('/signin') === true) { // only do this if on a contacts page
  client.callback(window.location.href.replace(`/#/`, `/#`)); // checks URL for information from GitLabs and saves it in localStorage (currently replaceing `/#/` with `/#` because JSO doesn't know how to handle a / between the hash and the rest of the information) (NetlifyCMS adds a / after the first #)
}

class request {
  constructor(url, method, responseType = 'json') {
    this.request = axios({
      url: url,
      method: method,
      baseURL: 'https://gitlab.com/api/v4',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${typeof window !== 'undefined' && localStorage.getItem('tokens-gitlab') ? JSON.parse(localStorage.getItem('tokens-gitlab'))[0].access_token : null}`
      },
      responseType: responseType,
      responseEncoding: 'utf8'
    });
  }
}

const tools = {
  getToken : async () => {
    client.getToken(options)
      .then((token) => {
        console.log(`[GitLab Auth] Bearer token: ${token.access_token}`);
        window.location.reload();
      })
      .catch((err) => {
        console.error(`[GitLab Auth] Error from passive loader`, err)
      })
  },
  wipeTokens : () => {
    client.wipeTokens();
    console.log(`[GitLab Auth] All tokens removed`);
  },
  checkToken : () => {
    const token = client.checkToken(options);
    if (token) {
      console.log(`[GitLab Auth] Found valid token: ${token.access_token}`);
      console.log(`[GitLab Auth] Token will expire at ${moment(token.expires)}`);
      return true;
    } else {
      tools.wipeTokens();
      window.localStorage.removeItem('state-campaignsStore')
      window.localStorage.removeItem('state-campaignsStore.nextURL')
      window.localStorage.removeItem('state-contactsStore')
      window.localStorage.removeItem('state.contacts-listMembershipMatch')
      return false;
    }
  }
}

export { request };
export { tools };
