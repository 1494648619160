import styled from '@emotion/styled/macro';
import theme from '../../utils/theme';

const FieldInside = styled.div`
  cursor: pointer;
  max-width: 800px;
  //margin: 0px auto;
  position: relative;
  padding: 1rem 1.5rem;
  //box-shadow: ${theme.color.border} 0px 0px 0px 1px inset;
  border-radius: ${theme.radius};
  transition: all 0.2s ease 0s;
  &:focus-within {
    //box-shadow: ${theme.color.primary['900']} 0px 0px 0px 2px inset;
    > label {
      color: ${theme.color.primary['800']};
    }
  }
`;

export { FieldInside };
