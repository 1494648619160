import { NavLink } from 'react-router-dom';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';
import css from '@emotion/css/macro';
import theme from '../../utils/theme';
import Icon from '@mdi/react';
import Color from 'color';

const NavbarItemTheme = {
  item: {
    height: '2.5rem',
    padding: '0 12px'
  },
  itemInside: {
    height: '2rem',
    borderRadius: theme.radius
  }
}

const NavMenuItemWrap = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  height: ${NavbarItemTheme.item.height};
  padding: ${NavbarItemTheme.item.padding};
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  user-drag: none;
  -webkit-tap-highlight-color: transparent;
  &.currentPage {
    cursor: default;
    pointer-events: none;
  }
}
`
const NavMenuItemWrapAnchor = NavMenuItemWrap.withComponent('a');
const NavMenuItemInside = styled.span`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  color: ${theme.color.mediumEmphasis};
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  border: none;
  height: 2rem;
  border-radius: ${NavbarItemTheme.itemInside.borderRadius};
  outline: none;
  transition: all 200ms ease 0s;
  ${NavMenuItemWrap}[aria-current='page'] &, ${NavMenuItemWrapAnchor}.currentPage & {
    color: ${theme.color.primary['900']};
    background-color: ${Color(theme.color.primary['900']).alpha(0.1).string()};}
  }
  ${NavMenuItemWrap}:not([aria-current='page']):hover &, ${NavMenuItemWrapAnchor}:not(.currentPage):hover & {
    color: ${theme.color.highEmphasis};
    background-color: ${Color(theme.color.highEmphasis).alpha(0.05).string()};}
  }
  ${NavMenuItemWrap}:not([aria-current='page']):active &, ${NavMenuItemWrapAnchor}:not(.currentPage):active & {
    color: ${theme.color.highEmphasis};
    background-color: ${Color(theme.color.highEmphasis).alpha(0.1).string()};}
`
const NavItemContents = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
`
const Label = styled.span`
  margin-left: 0.75rem;
  display: flex;
  flex: 1 1 0%;
  overflow: hidden;
  white-space: nowrap;
  font-family: ${theme.headlineFont};
  font-size: 0.875rem;
  font-weight: 600;
  opacity: ${({ collapsed }) => (collapsed ? "0" : "1")};
  transition: opacity 200ms ease 0s;
`
export default function NavBarItem(props) {
  const currentPageClass = () => {
    if (window.location.pathname === '/cms/') { // if cms page, check for matching hash as well
      if (window.location.pathname + window.location.hash === props.link) {
        return 'currentPage';
      }
    } else if (window.location.pathname === props.link) { // otherwise, just check for matching path
      return 'currentPage';
    }
  }
  if (props.link.indexOf('://') > 0 || props.link.indexOf('//') === 0 || props.link.indexOf('/cms/') === 0) { // absolute
      return (
        <NavMenuItemWrapAnchor href={props.link} className={currentPageClass()}>
          <NavMenuItemInside>
            <NavItemContents>
              <Icon path={props.icon} title={props.label} size={0.8336} css={css`margin: 0.375rem; min-width: 1.2504rem`} />
              <Label collapsed={props.collapsed}>{props.label}</Label>
            </NavItemContents>
          </NavMenuItemInside>
        </NavMenuItemWrapAnchor>
      )
  } else { // relative
    return (
      <NavMenuItemWrap exact to={props.link}>
        <NavMenuItemInside>
          <NavItemContents>
            <Icon path={props.icon} title={props.label} size={0.8336} css={css`margin: 0.375rem; min-width: 1.2504rem`} />
            <Label collapsed={props.collapsed}>{props.label}</Label>
          </NavItemContents>
        </NavMenuItemInside>
      </NavMenuItemWrap>
    )
  }
}

export { NavMenuItemWrapAnchor, NavMenuItemInside }