import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import theme from '../utils/theme';
import Icon from '@mdi/react';
import { mdiMagnify, mdiArrowRight } from '@mdi/js'
import Color from 'color';


const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-width: 200px;
  background-color: ${({ focus }) =>
    focus ? theme.color.elevatedSurfaceHighlight : theme.color.surfaceHighlight};
  border-radius: ${theme.radius};
  transition: 200ms;
  ${({ focus }) =>
    focus
      ? `box-shadow: inset 0 0 0 2px ${theme.color.primary['900']};`
      : `box-shadow: inset 0 0 0 0 ${theme.color.primary['900']};`}
  &:hover {
    background-color: ${theme.color.elevatedSurfaceHighlight};
  }
`;
const SearchIcon = styled(Icon)`
  position: absolute;
  z-index: 2;
  top: 0.5rem;
  left: 0.5rem;
  pointer-events: none;
  transition: 200ms;
  color: ${({ focus }) => (focus ? theme.color.mediumEmphasis : theme.color.disabled)};
`;
const GoIcon = styled(Icon)`
  position: absolute;
  z-index: 2;
  top: 2px;
  right: 2px;
  transition: 200ms;
  color: ${({ focus }) => (focus ? theme.color.mediumEmphasis : theme.color.disabled)};
  background-color: ${Color(theme.color.primary['900']).alpha(0.1).string()};
  height: calc(2.25rem - 4px);
  width: calc(2.25rem - 4px);
  padding: 0.5rem;
  border-radius: ${theme.radius};
  &:hover, &:focus {
    background-color: ${Color(theme.color.primary['900']).alpha(0.15).string()};
  }
  &:active {
    background-color: ${Color(theme.color.primary['900']).alpha(0.2).string()};
  }
`;
const EndWrap = styled.div`
  margin: 0.375rem;
  margin-left: 0;
`;

const SearchInput = styled.input`
  flex: 1;
  background-color: transparent;
  color: ${theme.color.highEmphasis};
  border: 0;
  caret-color: ${theme.color.primary['900']};
  outline: none;
  font-size: 0.875rem;
  z-index: 1;
  border: 0;
  padding: 0;
  padding-top: 0.625rem;
  padding-right: 0;
  padding-bottom: 0.625rem;
  padding-left: 2.25rem;
  line-height: 1;
  &::placeholder {
    color: ${theme.color.disabled};
  }
`;

const SearchBar = ({ placeholder, renderEnd, onChange, onKeyPress, className, value, searchButtonOnClick }) => {
  const [focus, setFocus] = useState();

  return (
    <SearchContainer focus={focus} className={className}>
      <SearchIcon path={mdiMagnify} title={`Search`} size={0.8336} focus={focus ? 1 : 0} />
      <SearchInput
        placeholder={placeholder}
        onChange={onChange}
        onKeyPress={onKeyPress}
        focus={focus ? 1 : 0}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        value={value}
      />
      {searchButtonOnClick // only show search button only if onClick prop is available 
        ? <GoIcon path={mdiArrowRight} title={`Complete Search`} focus={focus ? 1 : 0} onClick={searchButtonOnClick} />
        : null
      }
      <EndWrap>{renderEnd && renderEnd(focus)}</EndWrap>
    </SearchContainer>
  );
};

export default SearchBar;
