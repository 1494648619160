/** @jsxFrag React.Fragment */
import React from 'react';
import styled from '@emotion/styled/macro';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import Color from 'color';
import theme from '../utils/theme';
import axios from "axios";
import MD5 from "crypto-js/md5";
import Button from '../components/Button';
import ButtonGroup from '../components/ButtonGroup';
import moment from 'moment';
import Header from '../components/Header';
import { FieldInside, FieldLabel, ChildrenWrap, Checkbox, TextInput } from '../components/Inputs/'
import swal from 'sweetalert';


const requestCC = axios.create({
  baseURL: 'https://api.cc.email',
  headers: { 
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${typeof window !== 'undefined' && localStorage.getItem('tokens-constantContact') ? JSON.parse(localStorage.getItem('tokens-constantContact'))[0].access_token : null}`
  }
});

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '+1 ')
    return [intlCode, match[2], ' ', match[3], ' ', match[4]].join('')
  }
  return null
}

export default class PersonPage extends React.Component {
  state = {
    contact: {},
    contactLoading: true,
    engagement: [],
    engagementNextUrl: '',
    engagementLoading: true,
    listMembershipMatch: {},
    contactLists: [],
    editing: false
  }
  constructor(props) {
    super(props);
    this.getContactData = this.getContactData.bind(this);
    this.putModifiedContactPayload = this.putModifiedContactPayload.bind(this);
  }
  getContactData(url) {
    this.setState({
      contactLoading: true,
    })
    requestCC.get(url) // make an XMLHttpRequest with the configuration in requestCC 
    .then(data => {
      this.setState({
        contact: data.data, // add contact data to contact object in state
        contactLoading: false // tell state that all requested contact is loaded
      });
    })
    .catch(console.log);
  }
  getContactEngagement(url) {
    this.setState({
      engagementLoading: true,
    })
    requestCC.get(url) // make an XMLHttpRequest with the configuration in requestCC 
    .then(data => {
      this.setState({
        engagement: data.data.tracking_activities,
        engagementNextUrl: (data.data._links.next !== undefined) ? data.data._links.next.href : undefined, // save the url for the next batch of engagement data to state (and save as undefiend if no url is available)
        engagementLoading: false // tell state that all requested engagement data is loaded
      });
      document.getElementById('main').scrollTop = this.state.scrollTop; // set the scrollTop value to what it was before more contact rows were added
    })
    .catch(console.log);
  }
  matchContactListsToNameInObject() {
    let object = {};
    requestCC.get('/v3/contact_lists?include_count=false')
    .then(data => {
      data.data.lists.map(list =>
        object[list.list_id] = list.name
      )
      this.setState({ contactLists: data.data.lists });
    })
    .catch(console.log);
    this.setState({ listMembershipMatch: object });
  }
  putModifiedContactPayload() {
    if (typeof window !== undefined) {
      const original = this.state.contact;
      const payload = {
        ...original,
        first_name: document.querySelector('#first_name').value,
        last_name: document.querySelector('#last_name').value,
        email_address: {
          ...original.email_address,
          address: document.querySelector('#email_address').value
        },
        list_memberships: Array.from(document.querySelectorAll(`#listsCheckboxContainer input`)).filter(item => item.checked).map(item => item.id),
        phone_numbers: [
          {
            ...original.phone_numbers[0],
            phone_number: document.querySelector('#phone_number').value || 0,
            kind: (original.phone_numbers[0]) ? original.phone_numbers[0].kind || 'other' : 'other'
          }
        ],
        street_addresses: [
          {
            ...original.street_addresses[0],
            street: document.querySelector('#street_address_street').value,
            city: document.querySelector('#street_address_city').value,
            state: document.querySelector('#street_address_state').value,
            postal_code: document.querySelector('#street_address_postal_code').value,
            country: document.querySelector('#street_address_country').value,
            kind: (original.street_addresses[0]) ? original.street_addresses[0].kind || 'other' : 'other'
          }
        ],
        custom_fields: [
          {
            custom_field_id: 'dbf12c56-dc0a-11ea-851b-d4ae526edc76',
            value: document.querySelector('#custom_field_birthday').value
          }
        ]
      }
      const contactId = new URLSearchParams(window.location.search).get('id') // find the id parameter value in the URL with URLSearchParams
      requestCC.put(`/v3/contacts/${contactId}`, payload)
        .then(response => {
          swal({
            title: "Successfully saved contact!",
            icon: "success"
          });
          this.setState({ editing: false });
        })
        .catch(error => {
          console.log(error);
          swal({
            title: "Failed to save",
            text: "Try again or contact the webmaster (click your profile icon, and then click get help)"
          });
        })
    }
  }
  async deleteContact() {
    if (typeof window !== undefined) {
      const confirm = window.confirm('Are you sure you want to delete this contact?');
      if (confirm === true) {
        const contactId = new URLSearchParams(window.location.search).get('id') // find the id parameter value in the URL with URLSearchParams
        await requestCC.delete(`/v3/contacts/${contactId}`)
        .then(response => {
          window.alert(`Contact deleted | ${response}`);
          window.location = `/contacts/`;
        })
        .catch(error => {
          window.alert(`Error deleting contact: ${error}`);
          console.log(error);
        })
      }
    }
  }
  componentDidMount() {
    const contactId = new URLSearchParams(window.location.search).get('id') // find the id parameter value in the URL with URLSearchParams
    this.matchContactListsToNameInObject();
    this.getContactData(`/v3/contacts/${contactId}?include=custom_fields%2Clist_memberships%2Cphone_numbers%2Cstreet_addresses`);
    this.getContactEngagement(`/v3/reports/contact_reports/${contactId}/activity_details?tracking_activities_list=em_opens%2Cem_sends`);
  }
  render() {
    return (
      <div>
        <Header
          searchPlaceholder={`Search contacts`}
          searchOnKeyPress={(e)=>{if(e.key === 'Enter'){this.props.history.push(`/contacts/?search_query=${encodeURIComponent(e.target.value)}`)}}} // when enter/return pressed on keyboard, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          searchButtonOnClick={(e)=>this.props.history.push(`/contacts/?search_query=${encodeURIComponent(e.target.previousSibling.value)}`)} // when search button clicked, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          title={`Contact`}
          backButtonLink={`/contacts/`}
         >
           <ButtonGroup>
            {this.state.editing === false ?
              <Button onClick={()=>this.setState({ editing: true })}>Edit contact</Button>
            :
              <>
                <Button type={`danger`} onClick={this.deleteContact}>Delete contact</Button>
                <Button type={`danger`} onClick={()=>this.setState({ editing: false })}>Stop editing</Button>
                <Button type={`success`} onClick={this.putModifiedContactPayload}>Save changes</Button>
              </>
            }
          </ButtonGroup>
         </Header>
         {this.state.editing === false
          ?
          <PersonDiv>
            <div css={css`
              display: grid;
              grid-template-columns: 240px 1fr;
              grid-gap: 1.5rem;
            `}>
              <div>
                <img
                  css={css`margin-top: 1.9rem;`}
                  src={`https://www.gravatar.com/avatar/${
                    this.state.contact.email_address !== undefined
                    ? MD5(this.state.contact.email_address.address.toLowerCase().trim()).toString()
                    : MD5('example@example.com'.toLowerCase().trim()).toString()
                  }?s=240&d=https%3A%2F%2Fi.stack.imgur.com%2F34AD2.jpg`}
                  alt={`user profile gravitar`}
                />
                <SectionHeader>Lists</SectionHeader>
                <div>
                  {this.state.contact.list_memberships !== undefined ?
                    this.state.contact.list_memberships.map(id => (<span css={css`background: #0d2d31; padding: 4px 12px; margin: 4px; font-family: ${theme.contentFont}; font-size: 12px; font-weight: 400; white-space: nowrap; display: inline-flex;`}>{this.state.listMembershipMatch[id]}</span>))
                    : 'no record'
                  }
                </div>
                <SectionHeader>User Status</SectionHeader>
                <div css={css`
                  font-family: ${theme.contentFont};
                  font-size: 0.875rem;
                  margin-block-start: 0;
                  p {
                    color: ${theme.color.lowEmphasis};
                    margin-block-start: 0.4rem;
                    margin-block-end: 0.4rem;
                  }
                `}>
                  <p>{`Created on ${moment(this.state.contact.created_at).format('DD MMM YYYY [at] h:MM A')}`}</p>
                  <p>{`Modified on ${moment(this.state.contact.updated_at).format('DD MMM YYYY [at] h:MM A')}`}</p>
                  <p>{`Subscription: ${this.state.contact.email_address ? this.state.contact.email_address.permission_to_send : 'status unavaialble'}`}</p>
                </div>
                
              </div>
              <div>
                <h2 css={css`
                  font-family: ${theme.headlineFont};
                  font-size: 1.7rem;
                  font-weight: 500;
                  position: sticky;
                  z-index: 999;
                  top: calc(4.2rem + 1rem); // height of first header row + top margin of second header row
                  margin-block-end: 0;
                  width: fit-content;
                  color: ${theme.color.mediumEmphasis};
                `}>{this.state.contact.first_name} {this.state.contact.last_name}</h2>
                <p css={css`
                  font-family: ${theme.contentFont};
                  font-size: 0.875rem;
                  color: ${theme.color.mediumEmphasis};
                  margin-block-start: 0;
                `}>{`Modified on ${moment(this.state.contact.updated_at).format('DD MMM YYYY [at] h:MM A')}`}</p>
                <div css={css`
                  display: flex;
                  flex-direction: row;
                  gap: 6px;
                `}>
                  {/*send email button*/}
                  {this.state.contact.email_address !== undefined
                  ? <Button onClick={()=>window.location = `mailto:${this.state.contact.email_address.address}`}>Send email</Button>
                  : null
                  }
                  {/*call phone button*/}
                  {this.state.contact.phone_numbers !== undefined && this.state.contact.phone_numbers[0] !== undefined
                  ? <Button onClick={()=>window.location = `tel:${formatPhoneNumber(this.state.contact.phone_numbers[0].phone_number).split(" ").join("")}`}>Call phone</Button>
                  : null
                  }
                  {/*send text message button*/}
                  {this.state.contact.phone_numbers !== undefined && this.state.contact.phone_numbers[0] !== undefined
                  ? <Button onClick={()=>window.location = `sms:${formatPhoneNumber(this.state.contact.phone_numbers[0].phone_number).split(" ").join("")}`}>Send text</Button>
                  : null
                  }
                </div>
                <SectionHeader>Contact Information</SectionHeader>
                <ContactInfoGrid>
                  <div>
                    <ContactInfoItem>Email:</ContactInfoItem>
                    <ContactInfoItem>Phone:</ContactInfoItem>
                    <ContactInfoItem>Address:</ContactInfoItem>
                  </div>
                  <div>
                    <ContactInfoItem>{this.state.contact.email_address !== undefined ? this.state.contact.email_address.address : ''}</ContactInfoItem>
                    <ContactInfoItem>{this.state.contact.phone_numbers !== undefined && this.state.contact.phone_numbers[0] !== undefined ?
                      <span>{formatPhoneNumber(this.state.contact.phone_numbers[0].phone_number)}</span>
                      : ''}
                    </ContactInfoItem>
                    <ContactInfoItem>{this.state.contact.street_addresses !== undefined && this.state.contact.street_addresses[0] !== undefined ?
                      <>
                        <span>{this.state.contact.street_addresses[0].street}</span>
                        <span>{`${this.state.contact.street_addresses[0].city}, ${this.state.contact.street_addresses[0].state} ${this.state.contact.street_addresses[0].postal_code}`}</span>
                      </>
                      : ''}
                    </ContactInfoItem>
                  </div>
                </ContactInfoGrid>
                <SectionHeader>Other Information</SectionHeader>
                <ContactInfoGrid>
                  <div>
                    <ContactInfoItem>Birthday:</ContactInfoItem>
                    <ContactInfoItem>Country:</ContactInfoItem>
                  </div>
                  <div>
                    <ContactInfoItem>{this.state.contact.custom_fields !== undefined && this.state.contact.custom_fields[0] !== undefined ? this.state.contact.custom_fields[0].value : ''}</ContactInfoItem>
                    <ContactInfoItem>{this.state.contact.street_addresses !== undefined && this.state.contact.street_addresses[0] !== undefined ? this.state.contact.street_addresses[0].country : ''}</ContactInfoItem>
                  </div>
                </ContactInfoGrid>
                <SectionHeader>Engagement</SectionHeader>
                <EngagementCards engagement={this.state.engagement}></EngagementCards>
              </div>
            </div>
          </PersonDiv>
          :
          <div css={css`background-color: ${theme.color.background};`}>
            <TextInput
              name={`first_name`}
              value={this.state.contact.first_name}
              label='First Name'
            />
            <TextInput
              name={`last_name`}
              value={this.state.contact.last_name}
              label='Last Name'
            />
            <TextInput
              name={`email_address`}
              value={this.state.contact.email_address !== undefined ? this.state.contact.email_address.address : ''}
              label='Email Address'
            />
            <TextInput
              name={`phone_number`}
              value={this.state.contact.phone_numbers !== undefined && this.state.contact.phone_numbers[0] !== undefined ? formatPhoneNumber(this.state.contact.phone_numbers[0].phone_number) : ''}
              label='Phone Number'
            />
            <TextInput
              name={`custom_field_birthday`}
              value={this.state.contact.custom_fields !== undefined && this.state.contact.custom_fields[0] !== undefined ? this.state.contact.custom_fields[0].value : ''}
              label='Birthday'
            />
            <TextInput
              name={`street_address_street`}
              value={this.state.contact.street_addresses !== undefined && this.state.contact.street_addresses[0] !== undefined ? this.state.contact.street_addresses[0].street : ''}
              label='Street'
            />
            <TextInput
              name={`street_address_city`}
              value={this.state.contact.street_addresses !== undefined && this.state.contact.street_addresses[0] !== undefined ? this.state.contact.street_addresses[0].city : ''}
              label='City'
            />
            <TextInput
              name={`street_address_state`}
              value={this.state.contact.street_addresses !== undefined && this.state.contact.street_addresses[0] !== undefined ? this.state.contact.street_addresses[0].state : ''}
              label='State'
            />
            <TextInput
              name={`street_address_postal_code`}
              value={this.state.contact.street_addresses !== undefined && this.state.contact.street_addresses[0] !== undefined ? this.state.contact.street_addresses[0].postal_code : ''}
              label='Postal Code'
            />
            <TextInput
              name={`street_address_country`}
              value={this.state.contact.street_addresses !== undefined && this.state.contact.street_addresses[0] !== undefined ? this.state.contact.street_addresses[0].country : ''}
              label='Country'
            />
            <FieldInside>
              <FieldLabel>Lists</FieldLabel>
              <ChildrenWrap id={`listsCheckboxContainer`} css={css`
                    input[type="checkbox"] {
                      height: 1rem;
                      width: 1rem;
                      margin: 0;
                      &::before {
                        content: "";
                        box-shadow: inset 0px 0px 0px 1px ${theme.color.neutral['1000']};
                        background-color: ${theme.color.background};
                        height: 1rem;
                        width: 1rem;
                        margin: 0;
                        display: block;
                      }
                      &:hover::before, &:checked:hover {
                        box-shadow: inset 0px 0px 0px 1px ${theme.color.neutral['800']};
                      }
                      &:checked:hover::before {
                        box-shadow: inset 0px 0px 0px 2px ${theme.color.highEmphasis};
                      }
                      &:active::before, &:checked:active::before {
                        box-shadow: inset 0px 0px 0px 2px ${theme.color.neutral['800']};
                        background-color: ${theme.color.neutral['800']};
                      }
                      &:checked::before {
                        box-shadow: inset 0px 0px 0px 2px ${theme.color.primary['900']};
                        background-color: ${theme.color.primary['900']};
                        background-image: url('data:image/svg+xml;utf8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024" fill="black"><title></title><g id="icomoon-ignore"></g><path d="M873.5 233.5l45 45-534.5 535-278.5-279 45-45 233.5 233 489.5-489z"></path></svg>');
                        background-size: 1rem;
                      }
                    }
                    `}>
                {this.state.contactLists.map(item => {
                  return (
                    <Checkbox
                      name={item.list_id}
                      label={item.name}
                      checkedCheck={this.state.contact.list_memberships.includes(item.list_id) ? true : false}
                    />
                  )
                })}
              </ChildrenWrap>
            </FieldInside>
          </div>
         }
      </div>
    );
  }
}































const PersonDiv = styled.div`
  display: flow-root;
  background-color: ${theme.color.background};
  padding: 0 1.5rem;
`;
const ContactInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  font-family: ${theme.contentFont};
  font-size: 0.875rem;
  color: ${theme.color.mediumEmphasis};
`;
const ContactInfoItem = styled.div`
  margin: 0.7em 0;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  & > span {
    display: block;
  }
`;



class SectionHeader extends React.Component {
  render() {
    return (
      <div css={css`
        position: relative;
        margin-block-start: 1em;
        margin-block-end: 1em;
      `}>
        <h3 css={css`
          font-family: ${theme.headlineFont};
          font-size: 0.9rem;
          font-weight: 600;
          color: gray; // update!!!
          background-color: ${theme.color.background};
          margin: 0;
          z-index: 1;
          position: relative;
          width: fit-content;
          padding-right: 8px;
        `}>{this.props.children}</h3>
        <hr css={css`
          margin: 0;
          position: absolute;
          width: 100%;
          bottom: 0.45rem;
          border: none;
          border-bottom: 1px solid ${theme.color.border};
        `} />
      </div>
    )
  }
}



const trackingTypeMatch = {
  em_opens: 'Opened',
  em_sends: 'Received'
}

const EngagementCards = ({ engagement }) => {
  return (
    <div>
      {engagement.map((item) => (
        <span key={`${item.campaign_activity_id}.${item.tracking_activity_type}`}>
          <EngagementCard onClick={()=>{window.open(`https://em-ui.constantcontact.com/em-ui/em/frame/previewtest/${item.campaign_activity_id}`, '_blank', 'location=yes,height=600,width=1000,scrollbars=yes,status=yes,left=20,top=20'); return false;}}>
            <span>{`${trackingTypeMatch[item.tracking_activity_type]} email with id '${item.campaign_activity_id}'`}</span>
            <span>{moment(item.created_time).format('DD MMMM YYYY [at] h:mm A')}</span>
          </EngagementCard>
        </span>
      ))}
    </div>
  )
};

const EngagementCard = styled.div`
  width: fit-content;
  position: relative;
  padding: 0.2rem 1rem;
  margin: 0.8rem 0;
  border-radius: 0px;
  background-color: transparent;
  transition-duration: 200ms;
  transition-property: background-color;
  text-decoration: none;
  color: ${theme.color.mediumEmphasis};
  font-family: ${theme.contentFont};
  font-size: 0.875rem;
  &::before {
    content: '';
    height: 100%;
    width: 2px;
    background-color: ${Color(theme.color.highEmphasis).alpha(0.05).string()};
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
  &:hover, &:focus {
    color: ${theme.color.highEmphasis};
    background-color: ${Color(theme.color.highEmphasis).alpha(0.05).string()};
  }
  &:active {
    color: ${theme.color.highEmphasis};
    background-color: ${Color(theme.color.highEmphasis).alpha(0.1).string()};
  }
  > span {
    display: block;
    &:last-of-type {
      color: ${theme.color.lowEmphasis};
      font-size: 0.75rem;
    }
  }
`;