import React from "react"
import styled from '@emotion/styled/macro';
import theme from '../../utils/theme';
import Color from 'color';

const WorkflowStatusCardContainer = styled.a`
  background: ${({ color }) => Color(theme.color[color][color === 'neutral' ? 600 : 900]).alpha(0.15).string()};
  display: inline-flex;
  flex-direction: column;
  width: 8.5rem;
  height: 6rem;
  align-items: flex-start;
  justify-content: left;
  padding: 0.625rem;
  margin: 1rem 1rem 0 0;
  font-family: ${theme.contentFont};
  font-size: 0.75rem;
  font-weight: 500;
  white-space: nowrap;
  box-shadow: ${({ color }) => theme.color[color][color === 'neutral' ? 600 : 900]} 0px 0px 0px 1.25px inset;
  border-radius: ${theme.radius};
  color: ${({ color }) => theme.color[color][color === 'neutral' ? 600 : 900]};
  transition: all 200ms ease 0s;
  cursor: pointer;
  user-drag: none;
  user-select: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  &:hover, &:focus {
    background: ${({ color }) => Color(theme.color[color][color === 'neutral' ? 600 : 900]).alpha(0.2).string()};
    box-shadow: ${({ color }) => theme.color[color][color === 'neutral' ? 500 : 800]} 0px 0px 0px 1.25px inset;
  }
  &:active {
    background: ${({ color }) => Color(theme.color[color][color === 'neutral' ? 600 : 900]).alpha(0.3).string()};
    box-shadow: ${({ color }) => theme.color[color][color === 'neutral' ? 500 : 800]} 0px 0px 0px 1.25px inset;
  }
`;

class Icon extends React.Component {
  render() {
    return (
      <svg viewBox={`0 0 24 24`} style={{height: '2rem', marginBottom: '0.625rem', marginLeft: '-0.125rem'}}><path d={this.props.path} style={{fill: "currentcolor"}}></path></svg>
    )
  }
}

const Caption = styled.span`
  font-family: ${theme.headlineFont};
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({ color }) => Color(theme.color[color][100]).alpha(0.85).string()};
`

const Number = styled.span`
  font-family: ${theme.headlineFont};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
  color: ${theme.color.mediumEmphasis};
`

class WorkflowStatusCard extends React.Component {
  render() {
    return (
      <WorkflowStatusCardContainer color={this.props.color} href={`/cms/#/workflow`}>
        <Icon path={this.props.icon} />
        <Caption color={this.props.color}>{this.props.children}</Caption>
        <Number>{this.props.count}</Number>
      </WorkflowStatusCardContainer>
    )
  }
}

export { WorkflowStatusCard as default };
