import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';

export default class MainTable extends React.Component {
  render() {
    return (
      <table
        width={`100%`}
        border={0}
        cellpadding={5}
        cellspacing={0}
        css={css`
          width: 100%;
          max-width: 640px;
        `}
      >
        {this.props.children}
      </table>
    );
  }
}
