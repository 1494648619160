import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';

export default class TableHeader extends React.Component {
  render() {
    return <div css={css`
      position: sticky;
      top: 8.4rem;
      z-index: 100;
    `}>{this.props.children}</div>
  }
}