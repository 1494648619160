import React from 'react';
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import moment from 'moment';
import swal from 'sweetalert';
import { withRouter } from 'react-router-dom';
// utilities
import { tools as toolsCC, request as requestCC } from '../utils/constantContact';
import theme from '../utils/theme';
// components
import Header from '../components/Header';
import Button from '../components/Button';
import ButtonGroup from '../components/ButtonGroup';
import { TextInput, FieldInside, FieldLabel, ChildrenWrap, Checkbox } from '../components/Inputs'

const isTokenCC = toolsCC.checkToken();

class EmailsPage extends React.Component {
  state = {
    searchValue: '',
    email_html: JSON.parse(window.sessionStorage.getItem(`emails.emailToSend`)).email_html.replaceAll(/\/\*(.|\n)*?\*\//g, '') || '', //remove source mapping url comments inside css /*# ... */
    email_name: JSON.parse(window.sessionStorage.getItem(`emails.emailToSend`)).email_name || '',
    contactLists: []
  }
  constructor(props) {
    super(props);
    this.returnFormData = this.returnFormData.bind(this);
  }
  async test() {
    await new requestCC('/v3/contact_lists?limit=50&include_count=true').request
      .then(response => {
        this.setState({
          contactLists: response.data.lists
        })
      })
      .catch(console.log);
  }
  returnFormData() {
    return {
      emailSubject: document.getElementById("email_name").value,
      emailSenderName: document.getElementById("email_sender_name").value,
      emailSenderEmail: document.getElementById("email_sender_email").value,
      emailReplyEmail: document.getElementById("email_replyto_email").value,
      emailHTML: document.getElementById("email_html").value
    }
  }
  async postEmail() {
    const formData = this.returnFormData();
    const postData = {
      "name": formData.emailSubject + " [" + moment().format("YYYY-MM-DD HH:mm:ssZZ") + "]",
      "email_campaign_activities": [{
        "format_type": 5,
        "from_email": formData.emailSenderEmail,
        "reply_to_email": formData.emailReplyEmail,
        "from_name": formData.emailSenderName,
        "subject": formData.emailSubject,
        "html_content": formData.emailHTML,
        "preheader": "",
        "physical_address_in_footer": {
          "address_line1": "4400 Peachtree Dunwoody Road",
          "city": "Atlanta",
          "country_code": "US",
          "country_name": "United States",
          "organization_name": "Scouts BSA Troop 370",
          "postal_code": "30342",
          "state_code": "GA"
        }
      }]
    }
    const requestResponse = await new requestCC('/v3/emails', 'POST', postData).request
      .then(async response => {
        const data = {
          campaign_activity_id: response.data.campaign_activities[0].campaign_activity_id,
          campaign_id: response.data.campaign_id,
          role: response.data.campaign_activities[0].role,
          from_email: formData.emailSenderEmail,
          reply_to_email: formData.emailReplyEmail,
          from_name: formData.emailSenderName,
          subject: formData.emailSubject
        }
        return data;
      })
      .catch(console.log)
    return requestResponse;
  }
  async putEmail(postData) {
    postData.contact_list_ids = Array.from(document.querySelectorAll('#listsCheckboxContainer > div > input')).map(checkbox => checkbox.checked === true ? checkbox.id : undefined).filter(id => id !== undefined); // get checked the ids of the checked checkboxes
    const requestResponse = await new requestCC(`/v3/emails/activities/${postData.campaign_activity_id}`, 'PUT', postData).request
      .then(async response => {
        return response.data;
      })
      .catch(console.log)
    return requestResponse;
  }
  async scheduleEmail(postData) {
    const time = {
      scheduled_date: 0
    }
    const requestResponse = await new requestCC(`/v3/emails/activities/${postData.campaign_activity_id}/schedules`, 'POST', time).request
      .then(async response => {
        return response.data;
      })
      .catch(console.log)
    return requestResponse;
  }
  componentDidMount() {
    this.test();
  }
  render() {
    return (
      <>
        <Header
          searchPlaceholder={`Search emails on constantcontact.com`}
          searchOnKeyPress={(e)=>{if(e.key === 'Enter'){window.location = `https://campaign-ui.constantcontact.com/campaign/campaigns/list?search=${encodeURIComponent(e.target.value)}`}}} // when enter/return pressed on keyboard, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          searchButtonOnClick={(e)=>window.location = `https://campaign-ui.constantcontact.com/campaign/campaigns/list?search=${encodeURIComponent(e.target.previousSibling.value)}`} // when search button clicked, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          searchOnChange={(e) => {this.setState({ searchValue: e.target.value })}}
          title={`Send Email Campaign`}
          searchValue={this.state.searchValue}
        >
          <ButtonGroup>
            {isTokenCC === false
              ? <Button to={`/signin?account_type=Constant%20Contact&account_reason=to%20access%20contacts%20and%20emails&redirect_when_done=%2Femails%2F`}>Authenticate</Button>
              : <>
                  <Button 
                    type={`danger`}
                    onClick={() => {
                      console.warn('I need to be coded!')
                      swal({
                        title: "Are you sure?",
                        text: "If you cancel, the campaign will be permanantly discarded.",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      })
                      .then((discard) => {
                        if (discard) {
                          swal("The campaign will be discarded.", {
                            icon: "success",
                          })
                          .then((next) => {
                            window.sessionStorage.removeItem(`emails.emailToSend`);
                            this.props.history.push(`/`);
                          });
                        }
                      });
                    }}
                  >Cancel</Button>
                  <Button
                    type={`success`}
                    onClick={async () => {
                      await this.postEmail()
                        .then(async resultPost => {
                          await this.putEmail(resultPost)
                            .then(async resultPut => {
                              await this.scheduleEmail(resultPost)
                                .then(async resultSchedule => {
                                  if(resultSchedule) {
                                    swal({
                                      title: "Successfully scheduled campaign!",
                                      text: "Your email will be delivered within the next few minutes.",
                                      icon: "success",
                                    })
                                    .then((next) => {
                                      window.sessionStorage.removeItem(`emails.emailToSend`);
                                      this.props.history.push('/emails/');
                                    });
                                  } else {
                                    swal({
                                      title: "Failed to send",
                                      text: "Try again or contact the webmaster (click your profile icon, and then click get help)",
                                    })
                                  }
                                })
                            })
                        })
                    }}
                  >Send email</Button>
                </>
            }
          </ButtonGroup>
        </Header>
        <div css={css`
          display: grid;
          grid-template-columns: 1fr 480px;
          grid-template-rows: 1fr;
          grid-gap: 0.75rem;
          padding: 1.5rem 0;
          background-color: ${theme.color.background};
          height: calc(100% - 8.4rem);
        `}>
          <div>
            <h2 css={css`
              font-family: ${theme.headlineFont};
              font-size: 1.25rem;
              font-weight: 500;
              position: sticky;
              margin: 0 0 0 1.5rem;
              color: ${theme.color.mediumEmphasis};
            `}>Configure email</h2>
            <FieldInside css={css`
              height: 160px;
              overflow: auto;
            `}>
              <FieldLabel>Lists</FieldLabel>
              <ChildrenWrap id={`listsCheckboxContainer`} css={css`
                    display: flex;
                    flex-direction: column;
                    input[type="checkbox"] {
                      height: 1rem;
                      width: 1rem;
                      margin: 0;
                      &::before {
                        content: "";
                        box-shadow: inset 0px 0px 0px 1px ${theme.color.neutral['1000']};
                        background-color: ${theme.color.background};
                        height: 1rem;
                        width: 1rem;
                        margin: 0;
                        display: block;
                      }
                      &:hover::before, &:checked:hover {
                        box-shadow: inset 0px 0px 0px 1px ${theme.color.neutral['800']};
                      }
                      &:checked:hover::before {
                        box-shadow: inset 0px 0px 0px 2px ${theme.color.highEmphasis};
                      }
                      &:active::before, &:checked:active::before {
                        box-shadow: inset 0px 0px 0px 2px ${theme.color.neutral['800']};
                        background-color: ${theme.color.neutral['800']};
                      }
                      &:checked::before {
                        box-shadow: inset 0px 0px 0px 2px ${theme.color.primary['900']};
                        background-color: ${theme.color.primary['900']};
                        background-image: url('data:image/svg+xml;utf8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024" fill="black"><title></title><g id="icomoon-ignore"></g><path d="M873.5 233.5l45 45-534.5 535-278.5-279 45-45 233.5 233 489.5-489z"></path></svg>');
                        background-size: 1rem;
                      }
                    }
                    `}>
                {this.state.contactLists.map(item => {
                  return (
                    <Checkbox
                      name={item.list_id}
                      label={item.name}
                      favorite={item.favorite}
                    />
                  )
                })}
              </ChildrenWrap>
            </FieldInside>
            <TextInput
              name={`email_name`}
              value={this.state.email_name}
              label='Subject'
            />
            <TextInput
              name={`email_sender_name`}
              value={`Sheri Buehner`}
              label='Sender Name'
              disbaled={true}
            />
            <TextInput
              name={`email_sender_email`}
              value={`sheribuehner@gmail.com`}
              label='Sender Email'
              disbaled={true}
            />
            <TextInput
              name={`email_replyto_email`}
              value={`sheribuehner@gmail.com`}
              label='Reply Email'
              disbaled={true}
            />
            <TextInput
              name={`email_html`}
              value={this.state.email_html}
              label='HTML (Email Code)'
              disbaled={true}
            />
          </div>
          <div>
            <iframe srcDoc={this.state.email_html} title={`email-preview`} css={css`
              height: 100%;
              width: 100%;
              border: none;
              background-color: #f7f7f7;
            `}></iframe>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(EmailsPage);
