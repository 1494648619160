import React from 'react';
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import css from '@emotion/css/macro';
import NetlifyCMSApp from 'netlify-cms-app';
import Color from 'color';
// components
import Header from '../components/Header';
//import Button from '../components/Button';
//import ButtonGroup from '../components/ButtonGroup';
// utilities
import { default as theme } from '../utils/theme';
import withEmotion from '../components/cms/with-emotion';
// cms components
import NetlifyCMSConfig from '../components/cms/config';
import { uuidWidget, uuidWidgetPreview } from '../components/cms/widgets/uuid';
import { PostPreview } from '../components/cms/previews/post';
import { EmailNewsletterPreview } from '../components/cms/previews/email-newsletters';
import { EmailStandalonePreview } from '../components/cms/previews/email-standalone';
import { PostWidgetPreview } from '../components/cms/widgets/post-relation';
import { MiniPostWidgetPreview } from '../components/cms/widgets/minipost-relation';
import { CategoryMiniPostWidgetPreview } from '../components/cms/widgets/catminipost-relation';

const button = {
  base : css`
    font-family: ${theme.headlineFont};
    font-size: 14px;
    padding: 0px 0.75rem;
    line-height: 2rem;
    height: 2rem;
    font-weight: 600;
    text-align: center;
    border-radius: ${theme.radius};
    border: 0px;
    outline: 0px;
    cursor: pointer;
    white-space: nowrap;
    transition: all 200ms ease 0s;
    // in additon to main component:
    margin: 0;
    -webkit-tap-highlight-color: transparent;
  `,
  dropdown : css`
    padding-right: 1.65rem;
    &::after {
      top: 13px;
    }
  `,
  neutral : css`
    background-color: ${Color(theme.color.neutral['700']).alpha(0.2).string()};
    color: ${theme.color.neutral['300']};
    &:hover, &:focus {
      background-color: ${Color(theme.color.neutral['700']).alpha(0.35).string()};
    }
    &:active, &:active:focus {
      background-color: ${Color(theme.color.neutral['700']).alpha(0.1).string()};
    }
  `
};

const resetDeclarationInlineFlex = `
  font-family: unset;
  font-weight: unset;
  font-size: unset;
  line-height: unset;
  text-transform: unset;
  color: unset;
  letter-spacing: unset;
  display: block;
  border: none;
  border-radius: unset;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  transition: unset;
  background-color: unset;
`

const menu = {
  wrap: css`
    background-color: ${theme.color.surface};
    border-radius: ${theme.radius};
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 8px 2px, rgba(0, 0, 0, 0.25) 0px 16px 32px -8px;
    padding: 0.25rem;
    min-width: 200px;
    top: 32px;
  `,
  item : css`
    font-family: ${theme.headlineFont};
    padding: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    transition: all 200ms ease 0s;
    display: flex;
    align-items: center;
    border: none;
    border-radius: ${theme.radius};
    color: ${theme.color.highEmphasis};
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &:hover, &:focus {
      background-color: ${Color(theme.color.neutral['700']).alpha(0.2).string()};
    }
    &:active, &:active:focus {
      background-color: ${Color(theme.color.neutral['700']).alpha(0.1).string()};
    }
  `
}

const mainHeader = css`
  // main header
  header[class*='-AppHeader'] { // header
    position: fixed;
    height: 0;
    width: 100%;
    > div[class*='AppHeaderContent'] { // header content container
      height: 0;
      width: 100%;
      > nav { // navigation (contents, workflow, media)
        display: none
      }
      > div[class*='AppHeaderActions'] { // quick add and profile icon container
        width: 100%;
        display: flex;
        > div:first-of-type { // quick add container
          user-select: none;
          user-drag: none;
          position: fixed;
          top: 5.3rem;
          right: 1.5rem;
          > span[role=button] { // quick add button
            ${button.base};
            ${button.neutral};
            ${button.dropdown};
          }
          > div[role=menu] { // quick add options
            > ul {
              right: 0;
              left: auto;
              ${menu.wrap};
              > div {
                ${menu.item};
              }
            }
          }
        }
        > div:last-of-type { // profile icon
          display: none;
        }
      }
    }
  }
`;

const collectionControls = css`
  div[class*='-CollectionControlsContainer'] { // sort controls and view controls
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    display: block;
    > div:first-of-type { // view controls
      display: none;
    }
    > div:last-of-type { // sort controls
      position: fixed;
      top: 5.3rem;
      right: calc(1.5rem + 103px + 6px); // padding + quick add button + spacing
      z-index: 999;
      > span[role=button] { // sort dropdown button
        ${button.base};
        ${button.neutral};
        ${button.dropdown};
      }
      > div[role=menu] { // sort options
        > ul {
          right: 0;
          left: auto;
          ${menu.wrap};
          > div {
            ${menu.item};
          }
        }
      }
    }
  }
`;

const searchResultTitleCard = css`
  div[class*='SearchResultContainer-card'] {
    display: none;
  }
  h2[class*='-CollectionLabel'] {
    text-transform: unset;
    margin: 0;
    &::after {
      content: ':';
      margin-right: 5px;
    }
  }
`;

const listControlListCard = css`
  div[class*='-ListItem-listControlItem-ListControl'] button[class*='-TopBarButton-']:first-of-type > span { transform: rotate(180deg); }
  div[class*='-ListItem-listControlItem-listControlItemCollapsed-ListControl'] button[class*='-TopBarButton-']:first-of-type > span { transform: rotate(0deg); }
  div[class*='-ListItem-listControlItem-'] /* draggable list item container */ {
    display: flex;
    flex-direction: row;
    margin: 0.5rem 0;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 0.8);
    border: 1px solid ${theme.color.neutral[1400]};
    border-radius: ${theme.radius};
    > div[class*='NestedObjectLabel'] /* collapsed object/list item label */ {
      background-color: transparent;
      border-radius: 0;
      font-family: ${theme.contentFont};
      font-weight: 400;
      font-size: 0.875rem;
      border-left: 1px solid ${theme.color.neutral[1300]};
      user-select: none;
    }
    > div[class*='TopBar-StyledListItemTopBar-StyledListItemTopBar'] /* drag row and other controls */ {
      display: flex;
      flex-direction: column;
      position: unset;
      height: auto;
      background-color: transparent;
      > span /* drag piece */{
        height: 100%;
        width: 2.5rem;
      }
      > button /* close and collapse button */ {
        position: absolute;
        z-index: 99;
        height: 1.75rem;
        width: 2.5rem;
        background: transparent;
        color: ${theme.color.lowEmphasis};
        border-radius: 0;
        &:hover {
          color: ${theme.color.neutral[100]};
        }
        &:focus {
          outline: none;
        }
        &:active, &:active:focus {
          color: ${theme.color.neutral[200]};
        }
        &:first-of-type /* collapse */{
          right: calc(2.5rem + 1px);
          &:hover {
            background-color: ${theme.color.neutral[1300]};
          }
          &:focus {
            border: 1px solid ${theme.color.neutral[1300]};
          }
          &:active, &:active:focus {
            background-color: ${Color(theme.color.neutral[1300]).alpha(0.5).string()};
          }
        }
        &:last-of-type /* close */ {
          right: 1px;
          border-radius: 0 ${theme.radius} 0 0;
          &:hover {
            background-color: ${theme.color.red[1300]};
          }
          &:focus {
            border: 1px solid ${theme.color.red[1300]};
          }
          &:active, &:active:focus {
            background-color: ${Color(theme.color.red[1300]).alpha(0.5).string()};
          }
        }
      }
    }
    > div:nth-of-type(3) /* list item interior control container */ {
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      > div > div[class*='-ControlContainer'] /* override control container styles */ {
        margin: 0;
        border-left-width: 1px;
      }
    }
  }
`;

let editorFieldControls = {
  extensions : {
    markdown : {
      selector : `div[class*='cms-editor-']`,
      style : css`
        > div > div[class*='-EditorControlBar'] {
          div[class*='-ToolbarContainer'] {
            background-color: ${theme.color.background};
            border-radius: 0;
            min-height: unset;
            padding: 0;
            border-bottom: 1px solid ${theme.color.neutral[1400]};
            > div:first-of-type {
              filter: invert(1) hue-rotate(180deg);
              color: ${theme.color.neutral[700]};
              button:not(:disabled) {
                border-radius: ${theme.radius};
                background-color: transparent;
                transition: all 200ms ease 0s;
                -webkit-tap-highlight-color: transparent;
                &:hover, &:focus {
                  background-color: ${Color(theme.color.neutral['900']).alpha(0.15).string()};
                }
                &:active, &:active:focus {
                  background-color: ${Color(theme.color.neutral['900']).alpha(0.25).string()};
                }
              }
            }
            > div[class*='-ToolbarToggle'] {
              display: inline-flex;
              flex-direction: row;
              > span[class*='-ToolbarToggleLabel']:nth-of-type(2) {
                display: none; /* hide markdown text label */
              }
              > span[class*='-ToolbarToggleLabel']:first-of-type /* style rich text label */ {
                color: inherit;
                order: 2;
                font-family: ${theme.headlineFont};
                font-weight: 500;
                font-size: 0.875rem;
                user-select: none;
              }
              > button {
                > span[class*='-ToggleBackground'] {
                  height: 16px;
                  background-color: ${theme.color.primary[900]};
                }
                > span[class*='-ToggleHandle'] {
                  height: 10px;
                  width: 10px;
                  top: 5px;
                  left: 23px;
                  box-shadow: none;
                  border-radius: 50%;
                  background-color: ${theme.color.neutral[1600]};
                }
              }
            }
          }
        }
        > div > div:nth-of-type(2) {
          background: ${theme.color.background};
          border: none;
          border-radius: 0;
          h1, h2, h3, h4, h5, h6, li, p, span {
            color: ${theme.color.mediumEmphasis}
          }
        }
      `
    },
    dateTime : {
      selector: `div[class*='-DateTimeControl']`,
      style : css`
        div.rdtPicker /* date-time widget picker ui */ {
          background-color: ${theme.color.surface};
          border: 1px solid ${theme.color.neutral[1400]};
          box-shadow: 0px 2px 8px rgb(14 14 14 / 0.8);
          border-radius: ${theme.radius};
          color: ${theme.color.mediumEmphasis};
          font-family: ${theme.contentFont};
          font-size: 0.875rem;
          th {
            font-weight: 500;
            border-bottom: none;
          }
          td.rdtOld {
            color: ${theme.color.lowEmphasis};
          }
          th:not(.dow), td {
            background-color: transparent;
            transition: all 200ms ease 0s;
            -webkit-tap-highlight-color: transparent;
            &:hover, &:focus {
              background-color: ${Color(theme.color.neutral['900']).alpha(0.15).string()};
            }
            &:active, &:active:focus {
              background-color: ${Color(theme.color.neutral['900']).alpha(0.25).string()};
            }
            &.rdtActive {
              background-color: ${theme.color.primary[900]};
              color: ${theme.color.neutral[1600]};
            }
          }
        }
        > div[class*='-NowButton'] /* button */{
          right: 0.5rem;
          top: 2.875rem;
          > button {
            ${button.base};
            ${button.neutral};
            height: 1.65rem;
            line-height: 1.65rem;
          }
        }
      `
    },
    select : {
      selector : `div[class*='-container']`,
      style : css`
        > div[class*='control'] {
          background-color: transparent;
          > div:first-of-type /* chips container */ {
            color: ${theme.color.mediumEmphasis};
            font-family: ${theme.contentFont};
            > div[class*='-singleValue'] {
              color: inherit;
              font-family: inherit;
            }
            > div[class*='-multiValue'] {
              background-color: ${Color(theme.color.neutral[600]).alpha(0.15).string()};
              box-shadow: ${theme.color.neutral[600]} 0px 0px 0px 1.25px inset;
              color: inherit;
              border-radius: ${theme.radius};
              display: inline-flex;
              height: 1.25rem;
              align-items: center;
              justify-content: center;
              padding: 0 0.375rem;
              margin: 2px;
              font-family: inherit;
              font-size: 0.75rem;
              font-weight: 500;
              white-space: nowrap;
              div {
                padding: 0;
                margin: 0;
                color: inherit;
                font-size: inherit;
              }
            }
            input {
              color: inherit;
              font-family: inherit;
            }
          }
        }
        > div[class*='control'] + div[class*='-menu'] {
          margin: 0;
          background-color: ${theme.color.surface};
          border: 1px solid ${theme.color.neutral[1400]};
          border-radius: ${theme.radius};
          box-shadow: 0px 2px 8px rgb(14 14 14 / 0.8);
          font-family: ${theme.contentFont};
          font-size: 0.875rem;
          font-weight: 400;
          color: ${theme.color.mediumEmphasis};
          > div {
            max-height: 300px;
            overflow: hidden auto;
            padding: 0.25rem 0;
            position: relative;
            box-sizing: border-box;
            > div[class*='-option'] {
              background-color: transparent;
              transition: all 200ms ease 0s;
              -webkit-tap-highlight-color: transparent;
              padding: 0.5rem 1rem;
              &:hover, &:focus {
                background-color: ${Color(theme.color.neutral['700']).alpha(0.15).string()};
              }
              &:active, &:active:focus {
                background-color: ${Color(theme.color.neutral['700']).alpha(0.25).string()};
              }
            }
          }
        }
      `
    }
  },
}
editorFieldControls.core = css`
display: flex;
flex-direction: column;
border-left: 2px solid ${theme.color.neutral[1300]};
margin: 0.75rem 1.5rem 0 1.5rem;
padding: 1rem;
&:focus-within {
  border-left: 2px solid ${theme.color.primary[1200]};
  > input, > div, > textarea {
    &[type='text'],
    &[type='number'],
    &[class*='-TextControl']/* textarea fields */,
    &[class*='cms-editor-'] /* markdown widget */,
    &[class*='-DateTimeControl'] > div > input[type='text'] /* date time control */,
    &[class*='-container'] > div[class*='control']  /*select dropdown*/
      {
      border: 1px solid ${theme.color.primary[900]};
    }
  }
}
> label {
  order: 1;
  ${resetDeclarationInlineFlex}
  margin: 0 0 0.25rem 0;
  font-family: ${theme.headlineFont};
  font-weight: 500;
  font-size: 0.875rem;
  color: ${theme.color.lowEmphasis};
  user-select: none;
  &::before, &::after { content: none; }
}
> p[class*='-ControlHint'] {
  order: 2;
  ${resetDeclarationInlineFlex}
  margin: 0 0 0.5rem 0;
  font-family: ${theme.contentFont};
  font-weight: 400;
  font-size: 0.813rem;
  color: ${theme.color.lowEmphasis};
}
> input, > div, > textarea {
  order: 3;
  ${resetDeclarationInlineFlex}
  &[type='text'],
  &[type='number'],
  &[class*='-TextControl']/* textarea fields */,
  &[class*='cms-editor-'] /* markdown widget */,
  &[class*='-DateTimeControl'] > div > input[type='text'] /* date time control */,
  &[class*='-container'] > div[class*='control'] /* select dropdown */
  {
    background-color: transparent;
    box-shadow: 0px 2px 8px rgb(14 14 14 / 0.8);
    border: 1px solid ${theme.color.neutral[1400]};
    padding: 0;
    margin: 0.5rem 0 0 0;
    font-family: ${theme.contentFont};
    font-size: 0.875rem;
    border-radius: ${theme.radius};
  }
  &[type='text'],
  &[type='number'],
  &[class*='-TextControl']/* textarea fields */,
  &[class*='-DateTimeControl'] > div > input[type='text'] /* date time control */
  {
    padding: 0.5rem;
    color: ${theme.color.mediumEmphasis};
  }
  &[class*='-DateTimeControl'] > div > input[type='text'] /* date time control */ {
    margin: 0;
  }
}
/* select dropdown only (some styles are assosicated with all inputs, so check there as well) */
> ${editorFieldControls.extensions.select.selector} {
  ${editorFieldControls.extensions.select.style};
}
/* date time control only */
> ${editorFieldControls.extensions.dateTime.selector} {
  ${editorFieldControls.extensions.dateTime.style};
}
/* multi list object fields container */
> div[id*='-field-'] {
  display: flex;
  flex-direction: column;
  > div:nth-of-type(2) {
    ${listControlListCard};
  }
  > div[class*='-TopBarContainer'] {
    order: 2;
    background-color: transparent;
    padding: 0;
    margin: 0;
    div[class*='ExpandButtonContainer-ExpandButtonContainer'] {
      display: none;
    }
    button[class*='AddButton-button'] {
      ${button.base};
      ${button.neutral};
      width: 100%;
    }
  }
}
/* markdown widget only */
> ${editorFieldControls.extensions.markdown.selector} {
  ${editorFieldControls.extensions.markdown.style};
}
> div.cms-editor-raw > div > div[class*='-EditorControlBar'] div[class*='-ToolbarContainer'] > div[class*='-ToolbarToggle'] > button > span[class*='-ToggleHandle'] { left: -13px; background-color: ${theme.color.neutral[600]}; }
> div.cms-editor-raw > div > div[class*='-EditorControlBar'] div[class*='-ToolbarContainer'] > div[class*='-ToolbarToggle'] > button > span[class*='-ToggleBackground'] { background-color: ${theme.color.neutral[1600]}; border: 1px solid ${theme.color.neutral[600]}; }
`;

export default class CMSPage extends React.Component {
  state = {
    searchValue: (window.location.hash.includes('/search/') === true) ? decodeURIComponent(window.location.href.split("/").pop().split("access_token")[0]) : '' // set default
  }
  constructor(props) {
    super(props);
    this.autoLoginCMS = this.autoLoginCMS.bind(this);
  }
  TitleText = () => {
    if (window.location.hash === '#/workflow') { return 'Editorial Workflow (Draft Management)' }
    else if (window.location.hash === '#/collections/about') { return 'About Pages' }
    else if (window.location.hash === '#/collections/information') { return 'Information Pages' }
    else if (window.location.hash === '#/collections/members') { return 'Member Pages' }
    else if (window.location.hash === '#/collections/payments') { return 'Payment Pages' }
    else if (window.location.hash === '#/collections/signups') { return 'Signup Forms' }
    else if (window.location.hash === '#/collections/contact') { return 'Contact Forms' }
    else if (window.location.hash === '#/collections/posts') { return 'Posts/Announcements' }
    else if (window.location.hash === '#/collections/email-newsletters') { return 'Email Newsletters' }
    else if (window.location.hash === '#/collections/email-standalone') { return 'Standalone Emails' }
    else if (window.location.hash === '#/collections/settings') { return 'Site Settings' }
    else if (window.location.hash.includes('/entries') === true) { return 'Edit Entry' }
    else if (window.location.hash.includes('/new') === true) { return 'Create New Entry' }
    else if (window.location.hash.includes('/search/') === true) { return `Search results for "${decodeURIComponent(window.location.href.split("/").pop())}"` }
    else { return 'Netlify Content Management System' }
  };
  URLBasedButtonControl = () => {
    if (window.location.hash.includes('/entries') === true || window.location.hash.includes('/new') === true) {
      if (document.querySelector(`header[class*='-AppHeader']`)) {
        document.querySelector(`header[class*='-AppHeader']`).style.display = `none`;
      }
    } else {
      if (document.querySelector(`header[class*='-AppHeader']`)) {
        document.querySelector(`header[class*='-AppHeader']`).style.display = `block`;
      }
    }
  }
  async autoLoginCMS() { // login to the cms if the user is not logged in via the CMS login process
    const attemptLogin = function() { // attempt to login by sending a click event to the login button once it is avaialble
      const loginButton = document.querySelector(`button[class*='-LoginButton-button']`);
      const hasUrlAccessToken = window.location.hash.includes('#/access_token');
      return new Promise(resolve => setTimeout(() => {
        if (loginButton !== null && hasUrlAccessToken === false) { // only click the button when the button is available AND there is no access toeken in the url. The button appears before the CMS can proces the access token, and clicking it causes the process tom start over (leading to an infinite loop of not being able to log in)
          loginButton.dispatchEvent(new MouseEvent("click",{bubbles: true, cancellable: true})) // activate the click event on the login button to tigger a login
          return;
        }
        resolve();
      }, 1500)); 
    }
    const CMSUserData = window.localStorage.getItem('netlify-cms-user');
    while(CMSUserData === null) { // do this when there is no cms user data in localStorage (this means the user isn't logged in to the CMS)
      await attemptLogin();
    }
  }
  componentDidMount() {
    NetlifyCMSApp.init(NetlifyCMSConfig);
    NetlifyCMSApp.registerWidget('uuid', uuidWidget, uuidWidgetPreview);
    NetlifyCMSApp.registerPreviewTemplate('posts', withEmotion(PostPreview));
    NetlifyCMSApp.registerPreviewTemplate('email-newsletters', withEmotion(EmailNewsletterPreview));
    NetlifyCMSApp.registerPreviewTemplate('email-standalone', withEmotion(EmailStandalonePreview));
    NetlifyCMSApp.registerWidget('post', 'relation', withEmotion(PostWidgetPreview));
    NetlifyCMSApp.registerWidget('minipost', 'relation', withEmotion(MiniPostWidgetPreview));
    NetlifyCMSApp.registerWidget('catminipost', 'relation', withEmotion(CategoryMiniPostWidgetPreview));
    this.autoLoginCMS();
    NetlifyCMSApp.registerEventListener({
      name: 'prePublish',
      handler: function({ title, entry }) {
        let iframe = document.getElementsByTagName('iframe')[0];
        if (iframe.contentWindow.document.getElementById('chooseEmailSendCheck').checked === true) {
          const emailPreview = iframe.contentWindow.document.getElementById('email');
          // get css from virtual style sheet (from emotion)
          let css = [];
          [...document.querySelector(`iframe`).contentWindow.document.querySelectorAll(`style[data-emotion]`)].map(style => 
            [...style.sheet.cssRules].forEach(rule => 
              css.push(rule.cssText)
            )
          );
          emailPreview.querySelector(`head`).innerHTML = `<style>${css.join(' ')}</style>`;
          // get email content and prepare it for sending
          const email = document.createElement('div');
          email.innerHTML = emailPreview.outerHTML;
          const editIcons = email.querySelectorAll('.edit-icon'); 
          for (let i = 0; i < editIcons.length; i++) { // remove edit icons
            editIcons[i].parentNode.removeChild(editIcons[i]);
          }
          const divs = email.querySelectorAll('div');
          for (let i = 0; i < divs.length; i++) { // remove empty divs
            if (divs[i].textContent === "") {
              divs[i].remove()
            }
          }
          const params = { // params to pass to popup
            email_name: entry.getIn(['data', 'title']),
            //sender_name: null,
            //sender_email: null,
            //replyto_email: null,
            email_html: email.outerHTML
          }
          window.sessionStorage.setItem(`emails.emailToSend`, JSON.stringify(params));
        }
      },
    });
    NetlifyCMSApp.registerEventListener({
      name: 'postPublish',
      handler: function() {
        window.location.reload();
      }
    });
  }
  render() {
    return (
      <>
        <Header
          searchPlaceholder={`Search pages, posts, forms, and emails`}
          searchOnKeyPress={(e)=>{if (e.key === 'Enter') {window.location = `/cms/#/search/${encodeURIComponent(e.target.value)}`}}} // when enter/return pressed on keyboard, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          searchButtonOnClick={(e)=>window.location = `/cms/#/search/${encodeURIComponent(e.target.previousSibling.value)}`} // when search button clicked, get value of search box, uri encode it, and redirect to contacts page with prefilled search query
          searchOnChange={(e) => {this.setState({ searchValue: e.target.value })}}
          title={this.TitleText()}
          searchValue={this.state.searchValue}
        >
          {this.URLBasedButtonControl()}
        </Header>
        <div id={`nc-root`} css={css`
          width: 100%;
          height: calc(100% - 8.4rem);
          flex: 1;
          overflow: auto;
          position: relative;
          background-color: ${theme.color.background};

          section[class*='StyledAuthenticationPage'] {
            display: none; // hide the authentication page since autoLoginCMS() automatically clicks the login button
          }


      [class*='-AppMainContainer'] {
        min-width: unset;
        max-width: unset;
        > div:not([class*='-EditorContainer']) {
          margin: 20px 18px;
        }
        > div[class*='-EditorContainer'] {
          margin: 0;
          padding: 0;
          background-color: transparent;
          min-width: unset;
          .SplitPane {
            background-color: transparent;
            box-shadow: none;
            [class*='-PreviewPaneContainer-ControlPaneContainer'] {
              //padding: 20px 18px 0 18px;
            }
          }
          
        }
      }

      ${mainHeader}


      main {
        padding-left: 10px;
        div[class*='CollectionTopContainer-card-cardTop'] { // collection header card (title, create button, and description)
          box-shadow: none;
          border-radius: none;
          background-color: transparent;
          width: 100%;
          padding: 0;
          margin: 0;
          div[class*='-CollectionTopRow'] { // hide title and button
            display: none;
          }
          p[class*='-CollectionTopDescription-cardTopDescription'] { // description
            max-width: unset;
            margin: 0;
            font-family: ${theme.contentFont};
            color: ${theme.onBackground};
          }
        }



        ${collectionControls}

        ${searchResultTitleCard}


        li[class*='-ListCard-card'] {
          box-shadow: none;
          border-radius: 0;
          background-color: transparent;
          width: 100%;
          margin: 0 12px;
          color: rgb(211, 214, 223);
          &::before {
            content: '>';
            margin-right: 10px;
            font-family: "IBM Plex Sans","Roboto",sans-serif;
            font-size: 16px;
            font-weight: 500;
          }
          a[class*='-ListCardLink'] {
            margin: 4px 3px;
            padding: 5px 13px;
            width: fit-contents;
            display: inline-flex;
            color: rgb(211, 214, 223);
            background-color: transparent;
            transition: all 200ms ease 0s;
            &:hover {
              color: rgb(238, 239, 242);
              background-color: rgba(238, 239, 242, 0.05);
            }
            &:active {
              color: rgb(238, 239, 242);
              background-color: rgba(238, 239, 242, 0.1);
            }
            > h2 {
              font-family: ${theme.headlineFont};
              color: rgb(211, 214, 223);
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }
      aside[class*='-SidebarContainer-card'] {
        display: none;
      }
      // editor toolbar
      [class*='-EditorContainer'] > [class*='-ToolbarContainer'] {
        width: 0;
        height: 0;
        [class*='-ToolbarSectionMeta-toolbarSection'] {
          display: none;
        }
        [class*='-ToolbarSectionMain-toolbarSection'] {
          position: fixed;
          height: 4.2rem;
          top: 4.2rem;
          right: 0;
          padding: 0;
          margin: 0 1.5em;
          border: none;
          background-color: ${theme.color.background};
          [class*='-ToolbarSubSectionFirst'] {
            gap: 0.5em;
            > button {
              margin: 0;
              font-family: ${theme.headlineFont};
              font-size: 14px;
              padding: 0 0.75rem;
              line-height: 2rem;
              height: 2rem;
              font-weight: 600;
              text-align: center;
              border-radius: ${theme.radius};
              border: 0px;
              outline: 0px;
              cursor: pointer;
              white-space: nowrap;
              transition: all 200ms ease 0s;
              background-color: rgba(121, 130, 145, 0.2);
              color: rgb(211, 214, 223);
              &:hover, &:focus {
                background-color: rgba(121, 130, 145, 0.35);
              }
              &:active, &:active:focus {
                background-color: rgba(121, 130, 145, 0.1);
              }
            }
            > button[class*='-DeleteButton-lightRe'] {
              background-color: rgba(229, 90, 90, 0.2);
              color: rgb(255, 206, 206);
              &:hover, &:focus {
                background-color: rgba(229, 90, 90, 0.35);
              }
              &:active, &:active:focus {
                background-color: rgba(229, 90, 90, 0.1);
              }
            }
            // workflow/publish status dropdown
            > div {
              margin: 0;
              > span {
                font-family: ${theme.headlineFont};
                font-size: 14px;
                padding: 0px 1.6rem 0 0.75rem;
                line-height: 2rem;
                height: 2rem;
                font-weight: 600;
                text-align: center;
                border-radius: ${theme.radius};
                border: 0px;
                outline: 0px;
                cursor: pointer;
                white-space: nowrap;
                transition: all 200ms ease 0s;
                background-color: rgba(0, 173, 158, 0.2);
                color: rgb(183, 252, 246);
                &:hover, &:focus {
                  background-color: rgba(0, 173, 158, 0.35);
                }
                &:active, &:active:focus {
                  background-color: rgba(92, 231, 219, 0.1);
                }
                ${button.dropdown};
              }
            }
          }
          [class*='ToolbarSubSectionFirst-ToolbarSubSectionLast'] {
            > div {
              margin: 0;
              > span {
                font-family: ${theme.headlineFont};
                font-size: 14px;
                padding: 0px 1.6rem 0 0.75rem;
                line-height: 2rem;
                height: 2rem;
                font-weight: 600;
                text-align: center;
                border-radius: ${theme.radius};
                border: 0px;
                outline: 0px;
                cursor: pointer;
                white-space: nowrap;
                transition: all 200ms ease 0s;
                background-color: rgba(0, 173, 158, 0.2);
                color: rgb(183, 252, 246);
                &:hover, &:focus {
                  background-color: rgba(0, 173, 158, 0.35);
                }
                &:active, &:active:focus {
                  background-color: rgba(92, 231, 219, 0.1);
                }
                ${button.dropdown};
              }
              > div[role=menu] { // quick add options
                > ul {
                  right: 0;
                  left: auto;
                  ${menu.wrap};
                  > div {
                    ${menu.item};
                    span[class*='-IconWrapper'] {
                      color: ${theme.color.mediumEmphasis};
                    }
                  }
                }
              }
            }
          }
        }
        [class*='-ToolbarSectionBackLink-toolbarSection'] {
          top: 80px;
          [class*='-BackCollection'], [class*='-BackArrow'] {
            display: none;
          }
        }
      }




      div[class*='-NoPreviewContainer-card-splitPane'] {
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
      }

      div[class*='PreviewPaneContainer-ControlPaneContainer'] /* apply to control containers in main container */, div[class*='-listControlItem-ListControl'] > div /* apply to control containers in list items */ {
      padding: 0;
        > div > div[class*='ControlContainer'] /* control container */ {
          ${editorFieldControls.core};
        }
      }
      


      .Pane2 {
        div[class*='-PreviewPaneContainer'] {
          overflow: hidden;
          iframe {
            border-radius: 0;
          }
        }
      }

      div[class*='-ViewControls'] {
        position: absolute;
        top: unset;
        bottom: 1rem;
        right: 1rem;
        button {
          ${button.base};
          ${button.neutral};
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin-top: 10px;
          &::after {
            content: '';
            display: block;
            position: absolute;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background: ${theme.color.background};
            z-index: -1;
          }
        }
      }
























    `}></div>
    <Global 
      styles={css`
        ${listControlListCard};
        div[class*='-ListItem-listControlItem-ListControl'] > div:last-of-type > div > div[class*='ControlContainer'] /* control container */ {
          ${editorFieldControls.core};
          margin: 0;
          border-left-width: 1px;
        }
      `}
    />
      </>
    );
  }
}


