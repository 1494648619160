import React from 'react';
import { Remarkable } from 'remarkable';
// utilities
import * as CMSPreviewUtils from '../../../utils/injectPreviewCSS';
// cms components
import CardTable from '../email-components/version2/CardTable';
import { Title2 } from '../email-components/version2/Title2';
import { Subtitle } from '../email-components/version2/Subtitle';
import { Content } from '../email-components/version2/Content';

class PostWidgetPreview extends React.Component {
  componentDidUpdate() {
    CMSPreviewUtils.injectPreviewCSS(`style[data-emotion='iframe-post-relation-widget-css`, `iframe`);
    CMSPreviewUtils.styleMardownButtons(`iframe`);
  }
  componentDidMount() {
    CMSPreviewUtils.injectPreviewCSS(`style[data-emotion='iframe-post-relation-widget-css`, `iframe`);
    CMSPreviewUtils.styleMardownButtons(`iframe`);
  }
  render() {
    const { value, fieldsMetaData } = this.props;
    const post = fieldsMetaData && fieldsMetaData.getIn(['posts', value]);
    const md = new Remarkable({ html: true });
    return post ?
      <>
        <CardTable>
          <tr>
            <td>
              <Title2>{post.get('title')}</Title2>
              <Subtitle dangerouslySetInnerHTML={{__html: md.render(post.get('subtitle'))}} />
              <Content as={`span`} dangerouslySetInnerHTML={{__html: md.render(post.get('body'))}}></Content>
            </td>
          </tr>
        </CardTable>
      </>
    : null
  }
}

export { PostWidgetPreview };
