/** @jsxFrag React.Fragment */
import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';

class Checkbox extends React.Component {
  state = {
    checked: this.props.checkedCheck || false
  }
  handleClick = (event) => {
    this.setState({
      checked: !this.state.checked
    })
  }
  render() {
    return (
      <div 
        css={css`
          display: flex;
          padding: 0.3rem 0;
          font-family: roboto;
          font-size: 14px;
          align-items: center;
          order: ${
            this.props.favorite === true
            ? -1
            : 'unset'
          };
        `}
        id={`${this.props.name}-div`}
        checked={this.state.checked}
      >
        <input type='checkbox' name={this.props.name} checked={this.state.checked} onClick={this.handleClick} id={this.props.name}/>
        <label
          htmlFor={this.props.name}
          css={css`margin-left: 8px;`}
        >
          {this.props.label}
          {
            this.props.favorite === true
            ? ' ☆'
            : ''
          }
        </label>
      </div>
    );
  }
}

export { Checkbox };
