import React from 'react';

export default class ContainerTable extends React.Component {
  render() {
    return (
      <table
        width={`100%`}
        border={0}
        cellpadding={0}
        cellspacing={0}
      >
        {this.props.children}
      </table>
    );
  }
}
