import React from "react"
import styled from '@emotion/styled/macro';

const AvatarButton = styled.img`
  border-radius: 32px;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  background-size: cover;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 200ms ease 0s;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.75;
  }
`

function _AvatarButton(props) {
  return (
    <AvatarButton src={props.src} onClick={props.onClick}></AvatarButton>
  )
}

export {_AvatarButton as default}; 