import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';

export default class TableBodyList extends React.Component {
  render() {
    return <div css={css`
      height: 100%;
      width: 100%;
    `}>{this.props.children}</div>
  }
}