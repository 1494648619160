import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import theme from '../../utils/theme'

export default class TableWrap extends React.Component {
  render() {
    return <div css={css`
      display: flex;
      flex-direction: column;
      height: fit-content;
      background-color: ${theme.color.surface};
    `}>{this.props.children}</div>
  }
}