import React from "react"
import styled from '@emotion/styled/macro';
import theme from '../utils/theme';
import Color from 'color';

const Tag2 = styled.span`
  background: ${({ color }) => Color(theme.color[color][color === 'neutral' ? 600 : 900]).alpha(0.15).string()};
  display: inline-flex;
  height: 1.25rem;
  align-items: center;
  justify-content: center;
  padding: 0 0.375rem;
  margin: 2px;
  font-family: ${theme.contentFont};
  font-size: 0.75rem;
  font-weight: 500;
  white-space: nowrap;
  display: inline-flex;
  box-shadow: ${({ color }) => theme.color[color][color === 'neutral' ? 600 : 900]} 0px 0px 0px 1.25px inset;
  border-radius: ${theme.radius};
  color: ${({ color }) => theme.color[color][color === 'neutral' ? 600 : 900]};
`;

class Tag extends React.Component {
  render() {
    return (
      <Tag2 color={this.props.color}>
        {this.props.children}
      </Tag2>
    )
  }
}

export { Tag as default };
