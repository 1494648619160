import React from 'react';
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { request as requestGL, tools as toolsGL } from '../../utils/gitlab';
import moment from 'moment';
import { default as theme } from '../../utils/theme';

const isTokenGL = toolsGL.checkToken();

export default class ActivityList extends React.Component {
  state = {
    commitsLoading: false,
    commitsData: [],
    commitsFiltered: [], 
    commitsCustomCMS: [],
    members: []
  }
  files = {
    "Post/Announcement": {
      path: 'content/posts/posts/',
      name: 'post',
      fileType: 'md',
      cmsType: 'posts'
    },
    "Email Newsletter": {
      path: 'content/posts/email-newsletters/',
      name: 'email',
      fileType: 'md',
      cmsType: 'email-newsletters'
    },
    "Standalone Email": {
      path: 'content/posts/email-standalone/',
      name: 'email',
      fileType: 'md',
      cmsType: 'email-standalone'
    }
  }
  constructor(props) {
    super(props);
    this.getCommits = this.getCommits.bind(this);
    this.customDataArray = this.customDataArray.bind(this);
    this.getMembers = this.getMembers.bind(this);
  }
  async getCommits() {
    await new requestGL(`/projects/troop370%2Fwebsite/repository/commits?ref=master`).request
      .then(response => {
        this.setState({
          commitsLoading: false,
          commitsData: response.data,
          commitsFiltered: response.data.filter(commit => commit.message.includes('” in '))
        })
      })
      .catch(console.log);
    this.customDataArray();
  }
  async customDataArray() {
    const data = this.state.commitsFiltered;
    let newData = [];
    var counter = 0;
    for (let i = 0; counter < 6; i++) {
      if (data[i]) {
        const typeRaw = data[i].message.split(' in ')[1].toString(); // get the collection type from commit message
        const fileRaw = data[i].message.match(/“(.*?)”/)[0].replace('“', '').replace('”', '');

        // if the required information for the collection type is unavailable,
        // break now to avoid errors
        if (!this.files[typeRaw]) break;

        const filePath = this.files[typeRaw].path + fileRaw + '.' + this.files[typeRaw].fileType;
        const fileTitle = await new requestGL(`/projects/troop370%2Fwebsite/repository/files/${encodeURIComponent(filePath)}/raw?ref=master`, 'GET', 'text').request
        .then(response => { 
          const title = response.data.match(/title:(.*?)\n/)[0].replace('title: ', '').replace(/\n/, '');
          return title;
        })
        .catch(console.log);
        const action = data[i].message.split(' ')[0] + 'd';
        const isChangedCloseTimeSameAuthorFunction = () => {
          if (newData.length >= 1) {
            const dateSeconds = new Date(data[i].created_at).getTime();
            const thresholdDateSeconds = new Date(data[i - 1].created_at).getTime() - 86400; // within 1 day
            if (fileTitle === newData[i - 1].title) { // ensure that titles are duplicates
              if (action === newData[i -1].action) { // ensure that actions are duplicates
                if (dateSeconds > thresholdDateSeconds) {
                  //console.log({ title: fileTitle, date: new Date(data[i].created_at), dateSeconds: dateSeconds });
                  return true;
                }
              }
            }
          }
          return false;
        }
        const isChangedCloseTimeSameAuthor = isChangedCloseTimeSameAuthorFunction();
        const object = {
          id: data[i].id,
          similarToPrevious: isChangedCloseTimeSameAuthor,
          author_name: data[i].author_name,
          type: {
            raw: typeRaw,
            clean: this.files[typeRaw].name, // convert collection type in commit message to determined type
            cms: this.files[typeRaw].cmsType
          },
          file: fileRaw,
          path: filePath,
          title: fileTitle,
          message: data[i].message,
          action: action,
          created_at: data[i].created_at
        }
        newData.push(object);
        if (isChangedCloseTimeSameAuthor === false) {
          counter = counter + 1;
        }
      }
    }
    this.setState({
      commitsCustomCMS: newData
    })
  }
  async getMembers() {
    new requestGL(`https://gitlab.com/api/v4/groups/troop370/members`, 'GET', 'json').request
      .then(response => { 
        this.setState({
          members: response.data
        })
      });
  }
  async componentDidMount() {
    if (isTokenGL) {
      await this.getMembers();
      await this.getCommits();
    }
  }
  render() {
    return (
      <div css={css`
        margin-top: 0.75rem;
      `}>
        {this.state.commitsCustomCMS.map(commit => {
          if (commit.similarToPrevious === false) {
            return (
              <div css={css`
                font-family: ${theme.headlineFont};
                font-size: 0.75rem;
                font-weight: 400;
                color: ${theme.color.mediumEmphasis};
                padding: 0.5rem 0;
                display: flex;
                flex-direction: row;
                a {
                  font-family: ${theme.headlineFont};
                  font-size: 0.75rem;
                  font-weight: 700;
                  color: ${theme.color.mediumEmphasis};
                  text-decoration: none;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              `}>
                <div css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                `}>
                  <img
                    src={this.state.members.find(object => object.name === commit.author_name).avatar_url /*find avatar url inside members state array*/}
                    css={css`
                      border-radius: 50%;
                      height: 1.5rem;
                    `}
                    alt={``}
                  /> 
                  <div css={css`
                    margin: 0 0.5rem;
                  `}>
                    <a href={this.state.members.find(object => object.name === commit.author_name).web_url} target={`blank`}>{commit.author_name}</a> {commit.action} the {commit.type.clean} <a href={`/cms/#/collections/${commit.type.cms}/entries/${commit.file}`}>{commit.title}</a>
                  </div>
                </div>
                <div css={css`
                  margin: 0 0 0 auto;
                  color: ${theme.color.lowEmphasis};
                  white-space: nowrap;
                `}>{moment(commit.created_at).fromNow()}</div>
              </div>
            )
          }
          return null;
        })}
      </div>
    )
  }
}
