import styled from '@emotion/styled/macro';

export const Button = styled.a`
  font-family: roboto, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.0892857em;
  text-decoration: none;
  text-transform: uppercase;
  padding: 6px 8px;
  border: 1px solid rgb(128, 128, 128);
  color: rgb(0, 63, 135);
`;
