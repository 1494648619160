import React from 'react';
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { Remarkable } from 'remarkable';
// utilities
import * as CMSPreviewUtils from '../../../utils/injectPreviewCSS';
// cms components
import { Title3 } from '../email-components/version2/Title3';
import { Subtitle } from '../email-components/version2/Subtitle';
import { Button } from '../email-components/version2/Button';

class CategoryMiniPostWidgetPreview extends React.Component {
  componentDidUpdate() {
    CMSPreviewUtils.styleMardownButtons(`iframe`);
  }
  componentDidMount() {
    CMSPreviewUtils.styleMardownButtons(`iframe`);
  }
  render() {
    const { value, fieldsMetaData } = this.props;
    const post = fieldsMetaData && fieldsMetaData.getIn(['posts', value]);
    const md = new Remarkable({ html: true });
    return post ?
      <>
        <td>
          <Title3>{post.get('title')}</Title3>
          <Subtitle dangerouslySetInnerHTML={{__html: md.render(post.get('subtitle'))}} />
        </td>
        <td className={`button-td`} width={124} css={css`text-align: right; vertical-align: middle; padding: 0;`}>
          <p>
            <Button href={`https://troop370atlanta.org/posts/${post.get('id').toLowerCase()}`}>{post.get('buttonText')}</Button>
          </p>
        </td>    
      </>
    : null
  }
}

export { CategoryMiniPostWidgetPreview };
