import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import theme from '../../utils/theme'

export default class TableCell extends React.Component {
  render() {
    return <div css={css`
      font-family: ${theme.contentFont};
      font-size: 0.875rem;
      padding: 0 0.5rem;
      &:first-of-type {
        padding: 0 0.5rem 0 0;
      }
      color: ${theme.color.mediumEmphasis};
      /*display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;*/
      width: 15%;
      & + & {
        width: 15%;
      }
      & + & + & {
        width: 30%;
      }
      & + & + & + & {
        width: 25%;
      }
      & + & + & + & + & {
        width: 15%;
      }
    `}>{this.props.children}</div>
  }
}