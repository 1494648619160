const config = {
  config: {
    "backend": {
      "name": "gitlab",
      "repo": "troop370/website",
      "auth_type": "pkce",
      "app_id": "5c5039b822028559fce9fed90c5b50cb14910cdba75b1314d1fc1d3b434ecd45",
      "commit_messages": {
        "create": "create “{{slug}}” in {{collection}}",
        "update": "update “{{slug}}” in {{collection}}",
        "delete": "remove “{{slug}}” in {{collection}}",
        "uploadMedia": "upload “{{path}}”",
        "deleteMedia": "remove “{{path}}”",
        "openAuthoring": "{{message}}"
      }
    },
    "load_config_file": false,
    "local_backend": true,
    "logo_url": "/img/troop370-logo/logo-color.svg",
    "publish_mode": "editorial_workflow",
    "media_folder": "static/cms-uploads",
    "public_folder": "/cms-uploads",
    "collections": [
      {
        "name": "about",
        "label": "About Pages",
        "label_singular": "About Page",
        "folder": "content/about",
        "create": false,
        "delete": false,
        "extension": "md",
        "slug": "{{title}}",
        "fields": [
          {
            "label": "Title",
            "name": "title",
            "widget": "string"
          },
          {
            "label": "Subtitle",
            "name": "subtitle",
            "widget": "text",
            "required": false
          },
          {
            "label": "Body",
            "name": "body",
            "widget": "markdown"
          },
          {
            "label": "Weight/Order",
            "name": "weight",
            "widget": "number",
            "required": false
          },
          {
            "label": "Columns",
            "name": "columns",
            "widget": "number",
            "max": 2,
            "min": 1,
            "required": false
          },
          {
            "label": "Aliases",
            "name": "aliases",
            "widget": "list",
            "required": false
          },
          {
            "label": "Custom CSS",
            "name": "style",
            "widget": "code",
            "default_language": "css",
            "allow_language_selection": false,
            "output_code_only": true,
            "required": false
          }
        ]
      },
      {
        "name": "information",
        "label": "Information Pages",
        "label_singular": "Information Page",
        "description": "The home of general information for the Troop 370 website. Information about events and member-specific content does not belong here. To make changes to the forms and documents page, contact the webmaster.",
        "folder": "content/information",
        "create": true,
        "delete": true,
        "extension": "md",
        "slug": "{{slug}}",
        "fields": [
          {
            "label": "Slug",
            "name": "slug",
            "widget": "string"
          },
          {
            "label": "Title",
            "name": "title",
            "widget": "string"
          },
          {
            "label": "Subtitle",
            "name": "subtitle",
            "widget": "text",
            "required": false
          },
          {
            "label": "Body",
            "name": "body",
            "widget": "markdown"
          },
          {
            "label": "Enable Password Protection",
            "name": "isSecure",
            "widget": "boolean",
            "required": false
          },
          {
            "label": "Weight/Order",
            "name": "weight",
            "widget": "number",
            "required": false
          },
          {
            "label": "Columns",
            "name": "columns",
            "widget": "number",
            "max": 2,
            "min": 1,
            "required": false
          },
          {
            "label": "Alises",
            "name": "aliases",
            "widget": "list",
            "required": false
          },
          {
            "label": "Custom CSS",
            "name": "style",
            "widget": "code",
            "default_language": "css",
            "allow_language_selection": false,
            "output_code_only": true,
            "required": false
          },
          {
            "label": "Menu Visibility",
            "name": "navVisibility",
            "widget": "select",
            "multiple": false,
            "required": false,
            "default": "show",
            "options": [
              "show",
              "hide"
            ]
          }
        ]
      },
      {
        "name": "members",
        "label": "Member Pages",
        "label_singular": "Member Page",
        "description": "Member-specific content and information.",
        "folder": "content/members",
        "create": true,
        "delete": true,
        "extension": "md",
        "slug": "{{title}}",
        "fields": [
          {
            "label": "Title",
            "name": "title",
            "widget": "string"
          },
          {
            "label": "Subtitle",
            "name": "subtitle",
            "widget": "text",
            "required": false
          },
          {
            "label": "Body",
            "name": "body",
            "widget": "markdown"
          },
          {
            "label": "Enable Password Protection",
            "name": "isSecure",
            "widget": "boolean",
            "required": false
          },
          {
            "label": "Weight/Order",
            "name": "weight",
            "widget": "number",
            "required": false
          },
          {
            "label": "Columns",
            "name": "columns",
            "widget": "number",
            "max": 2,
            "min": 1,
            "required": false
          },
          {
            "label": "Alises",
            "name": "aliases",
            "widget": "list",
            "required": false
          },
          {
            "label": "Custom CSS",
            "name": "style",
            "widget": "code",
            "default_language": "css",
            "allow_language_selection": false,
            "output_code_only": true,
            "required": false
          },
          {
            "label": "Menu Visibility",
            "name": "navVisibility",
            "widget": "select",
            "multiple": false,
            "required": false,
            "default": "show",
            "options": [
              "show",
              "hide"
            ]
          }
        ]
      },
      {
        "name": "payments",
        "label": "Payment Pages",
        "label_singular": "Payment Page",
        "folder": "content/payments",
        "create": false,
        "delete": false,
        "extension": "md",
        "slug": "{{title}}",
        "fields": [
          {
            "label": "Title",
            "name": "title",
            "widget": "string"
          },
          {
            "label": "Subtitle",
            "name": "subtitle",
            "widget": "text",
            "required": false
          },
          {
            "label": "Alert",
            "name": "alert",
            "widget": "text",
            "required": false
          },
          {
            "label": "Body",
            "name": "body",
            "widget": "markdown"
          },
          {
            "label": "Columns",
            "name": "columns",
            "widget": "number",
            "max": 2,
            "min": 1,
            "required": false
          },
          {
            "label": "Aliases",
            "name": "aliases",
            "widget": "list",
            "required": false
          },
          {
            "label": "Custom CSS",
            "name": "style",
            "widget": "code",
            "default_language": "css",
            "allow_language_selection": false,
            "output_code_only": true,
            "required": false
          }
        ]
      },
      {
        "name": "signups",
        "label": "Signup Forms",
        "label_singular": "Signup Form",
        "folder": "content/members/signups",
        "create": true,
        "delete": true,
        "extension": "md",
        "slug": "{{title}}",
        "fields": [
          {
            "label": "Layout",
            "name": "layout",
            "widget": "hidden",
            "default": "signup"
          },
          {
            "label": "Title",
            "name": "title",
            "widget": "string"
          },
          {
            "label": "Form URL",
            "name": "src",
            "widget": "string"
          },
          {
            "label": "Enable Password Protection",
            "name": "isSecure",
            "widget": "boolean",
            "required": false
          },
          {
            "label": "Alises",
            "name": "aliases",
            "widget": "list",
            "required": false
          },
          {
            "label": "Menu Visibility",
            "name": "navVisibility",
            "widget": "hidden",
            "default": "hide"
          }
        ]
      },
      {
        "name": "contact",
        "label": "Contact Forms",
        "label_singular": "Contact Form",
        "folder": "content/contact",
        "create": true,
        "delete": true,
        "extension": "html",
        "slug": "{{title}}",
        "fields": [
          {
            "label": "Layout",
            "name": "layout",
            "widget": "hidden",
            "default": "contact"
          },
          {
            "label": "Title",
            "name": "title",
            "widget": "string"
          },
          {
            "label": "Subtitle",
            "name": "subtitle",
            "widget": "code",
            "default_language": "html",
            "allow_language_selection": false,
            "output_code_only": true,
            "required": false
          },
          {
            "label": "Body",
            "name": "body",
            "widget": "code",
            "default_language": "html",
            "allow_language_selection": false,
            "output_code_only": true
          },
          {
            "label": "Alises",
            "name": "aliases",
            "widget": "list",
            "required": false
          },
          {
            "label": "Menu Visibility",
            "name": "navVisibility",
            "widget": "hidden",
            "default": "hide"
          }
        ]
      },
      {
        "name": "posts",
        "label": "Posts/Announcements",
        "label_singular": "Post/Announcement",
        "folder": "content/posts/posts",
        "create": true,
        "extension": "md",
        "slug": "{{id}}",
        "fields": [
          {
            "label": "Title",
            "hint": "Appears above the post test on the website and in the email.",
            "name": "title",
            "widget": "string"
          },
          {
            "label": "Subtitle",
            "hint": "This text appears under the title. It provides a very brief overview of the post content. A date or a short phrase could go here.",
            "name": "subtitle",
            "widget": "string"
          },
          {
            "label": "Author",
            "name": "author",
            "widget": "string"
          },
          {
            "label": "Categories",
            "name": "categories",
            "widget": "select",
            "multiple": true,
            "required": false,
            "options": [
              "advancement",
              "camping",
              "high adventure",
              "fundraiser",
              "service",
              "meeting"
            ]
          },
          {
            "label": "Tags",
            "name": "tags",
            "widget": "select",
            "multiple": true,
            "required": false,
            "options": [
              "woodruff",
              "camp cards",
              "pine straw",
              "information session",
              "merit badge",
              "new parents",
              "northern tier",
              "okpik",
              "philmont",
              "summit",
              "cancelled",
              "sea base",
              "scuba",
              "summer camp",
              "new river",
              "backpacking",
              "in-state",
              "out-of-state",
              "new scouts",
              "virtual",
              "scout",
              "tenderfoot",
              "second class",
              "first class",
              "star",
              "life",
              "eagle",
              "life to eagle",
              "whitewater",
              "climbing",
              "shooting sports",
              "privative skills",
              "uganda",
              "winter camp",
              "bert adams",
              "mlk",
              "camporee",
              "phoenix district",
              "hiking",
              "mountain biking",
              "first aid",
              "wilderness first aid",
              "hornaday",
              "conservation",
              "webelos",
              "parent night",
              "camp kick off",
              "scout sunday",
              "scout weekend",
              "elections",
              "troop elections",
              "order of the arrow",
              "oa elections",
              "venturing",
              "crew 370",
              "troop 370",
              "troop 372",
              "competition",
              "knots",
              "cooking",
              "dutch oven",
              "cobbler",
              "cake",
              "peace light",
              "national jamboree",
              "world jamboree",
              "patrol leader",
              "senior patrol leader",
              "assistant senior patrol leader",
              "assistant patrol leader",
              "scoutmaster",
              "assistant scoutmaster",
              "junior assistant scoutmaster",
              "quartermaster",
              "scribe",
              "troop committee",
              "committee member",
              "troop committee chairman",
              "district chairman",
              "troop guide",
              "den chief",
              "oa troop representative",
              "instructor",
              "bugler",
              "outdoor ethics guide",
              "leave no trace",
              "leave no trace trainer",
              "librarian",
              "historian",
              "webmaster",
              "chaplain",
              "chaplain aide",
              "gym",
              "gymmaster",
              "policies",
              "patrol leaders' council",
              "chartered organization representative",
              "atlanta area council",
              "alert"
            ]
          },
          {
            "label": "Enable Password Protection",
            "name": "isSecure",
            "widget": "boolean",
            "required": false
          },
          {
            "label": "Publish Date",
            "name": "date",
            "widget": "datetime",
            "dateFormat": "DD MMMM YYYY",
            "timeFormat": "",
            "format": "YYYY-MM-DD",
            "pickerUtc": true
          },
          {
            "label": "Last Edited",
            "name": "lastDate",
            "widget": "datetime",
            "dateFormat": "DD MMMM YYYY",
            "timeFormat": "",
            "format": "YYYY-MM-DD",
            "pickerUtc": true
          },
          {
            "label": "Body",
            "name": "body",
            "widget": "markdown"
          },
          {
            "label": "Type ID",
            "name": "typeID",
            "widget": "hidden",
            "default": "post"
          },
          {
            "label": "UUID (read only)",
            "name": "id",
            "widget": "uuid",
            "suffix": "post"
          },
          {
            "label": "Button Text (for mini post version)",
            "name": "buttonText",
            "widget": "string",
            "default": "Read more"
          }
        ]
      },
      {
        "name": "email-newsletters",
        "label": "Email Newsletters",
        "label_singular": "Email Newsletter",
        "folder": "content/posts/email-newsletters",
        "create": true,
        "extension": "md",
        "slug": "{{year}}-{{month}}-{{day}}-{{slug}}.sec",
        "summary": "{{title}} – {{date}}",
        "fields": [
          {
            "label": "Title",
            "name": "title",
            "widget": "string"
          },
          {
            "label": "Date",
            "name": "date",
            "widget": "datetime",
            "dateFormat": "DD MMMM YYYY",
            "timeFormat": "",
            "format": "MMMM DD, YYYY",
            "pickerUtc": true
          },
          {
            "label": "Pinned Announcements (Mini)",
            "name": "pinned",
            "widget": "list",
            "allow_add": true,
            "default": [
  
            ],
            "fields": [
              {
                "label": "Post",
                "name": "post-selector",
                "widget": "minipost",
                "optionsLength": 50,
                "collection": "posts",
                "searchFields": "title",
                "valueField": "title",
                "displayFields": [
                  "{{title}}"
                ]
              }
            ]
          },
          {
            "label": "Announcements (Full)",
            "name": "announcements",
            "widget": "list",
            "allow_add": true,
            "default": [
  
            ],
            "fields": [
              {
                "label": "Post",
                "name": "post-selector",
                "widget": "post",
                "optionsLength": 50,
                "collection": "posts",
                "searchFields": "title",
                "valueField": "title",
                "displayFields": [
                  "{{title}}"
                ]
              }
            ]
          },
          {
            "label": "Advancement Mini Posts",
            "name": "advancement-mini-posts",
            "widget": "list",
            "allow_add": true,
            "default": [
  
            ],
            "fields": [
              {
                "label": "Post",
                "name": "post-selector",
                "widget": "catminipost",
                "optionsLength": 50,
                "collection": "posts",
                "searchFields": "title",
                "valueField": "title",
                "displayFields": [
                  "{{title}}"
                ]
              }
            ]
          },
          {
            "label": "Camping Mini Posts",
            "name": "camping-mini-posts",
            "widget": "list",
            "allow_add": true,
            "default": [
  
            ],
            "fields": [
              {
                "label": "Post",
                "name": "post-selector",
                "widget": "catminipost",
                "optionsLength": 50,
                "collection": "posts",
                "searchFields": "title",
                "valueField": "title",
                "displayFields": [
                  "{{title}}"
                ]
              }
            ]
          },
          {
            "label": "High Adventure Mini Posts",
            "name": "high-adventure-mini-posts",
            "widget": "list",
            "allow_add": true,
            "default": [
  
            ],
            "fields": [
              {
                "label": "Post",
                "name": "post-selector",
                "widget": "catminipost",
                "optionsLength": 50,
                "collection": "posts",
                "searchFields": "title",
                "valueField": "title",
                "displayFields": [
                  "{{title}}"
                ]
              }
            ]
          },
          {
            "label": "Fundraiser Mini Posts",
            "name": "fundraiser-mini-posts",
            "widget": "list",
            "allow_add": true,
            "default": [
  
            ],
            "fields": [
              {
                "label": "Post",
                "name": "post-selector",
                "widget": "catminipost",
                "optionsLength": 50,
                "collection": "posts",
                "searchFields": "title",
                "valueField": "title",
                "displayFields": [
                  "{{title}}"
                ]
              }
            ]
          },
          {
            "label": "Service Mini Posts",
            "name": "service-mini-posts",
            "widget": "list",
            "allow_add": true,
            "default": [
  
            ],
            "fields": [
              {
                "label": "Post",
                "name": "post-selector",
                "widget": "catminipost",
                "optionsLength": 50,
                "collection": "posts",
                "searchFields": "title",
                "valueField": "title",
                "displayFields": [
                  "{{title}}"
                ]
              }
            ]
          },
          {
            "label": "Calendar",
            "name": "calendar",
            "widget": "list",
            "allow_add": true,
            "default": [
  
            ],
            "fields": [
              {
                "label": "Select Month",
                "name": "month",
                "widget": "select",
                "options": [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December"
                ]
              },
              {
                "label": "Month Events",
                "name": "month-events",
                "widget": "markdown",
                "minimal": true
              }
            ]
          },
          {
            "label": "Newsletter Design",
            "name": "version",
            "widget": "select",
            "multiple": false,
            "required": false,
            "default": "version2",
            "options": [
              "version2",
              "version2b"
            ]
          }
        ]
      },
      {
        "name": "email-standalone",
        "label": "Standalone Emails",
        "label_singular": "Standalone Email",
        "folder": "content/posts/email-standalone",
        "create": true,
        "extension": "md",
        "slug": "{{year}}-{{month}}-{{day}}-{{slug}}.sec",
        "summary": "{{title}} – {{date}}",
        "fields": [
          {
            "label": "Title",
            "name": "title",
            "widget": "string"
          },
          {
            "label": "Date",
            "name": "date",
            "widget": "datetime",
            "dateFormat": "DD MMMM YYYY",
            "timeFormat": "",
            "format": "MMMM DD, YYYY",
            "default": "",
            "required": false
          },
          {
            "label": "Author",
            "name": "author",
            "widget": "string"
          },
          {
            "label": "Content",
            "name": "content",
            "widget": "markdown"
          },
          {
            "label": "Email Design",
            "name": "version",
            "widget": "select",
            "multiple": false,
            "required": false,
            "default": "version2",
            "options": [
              "version2"
            ]
          }
        ]
      },
      {
        "name": "settings",
        "label": "Settings",
        "label_singular": "Setting",
        "files": [
          {
            "label": "Categories",
            "name": "categories-settings",
            "file": "data/settings/categories.yml",
            "fields": [
              {
                "label": "Categories",
                "label_singular": "Category",
                "name": "categories",
                "widget": "list",
                "collapsed": false,
                "field": {
                  "label": "Name",
                  "name": "name",
                  "widget": "string"
                }
              }
            ]
          },
          {
            "label": "Tags",
            "name": "tags-settings",
            "file": "data/settings/tags.yml",
            "fields": [
              {
                "label": "Tags",
                "label_singular": "Tag",
                "name": "tags",
                "widget": "list",
                "collapsed": false,
                "field": {
                  "label": "Name",
                  "name": "name",
                  "widget": "string"
                }
              }
            ]
          }
        ]
      }
    ]
  }
}

export default config;
