import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';

export default class BackgroundTable extends React.Component {
  render() {
    return (
      <table
        width={`100%`}
        data-imagetype={`External`}
        background={`https://imgssl.constantcontact.com/letters/images/backgrounds/citygrid.png`}
        border={0}
        cellpadding={0}
        cellspacing={0}
        css={css`
          background: url(https://imgssl.constantcontact.com/letters/images/backgrounds/citygrid.png) repeat top left;
          background-color: #ffffff;
        `}
      >
        {this.props.children}
      </table>
    );
  }
}
