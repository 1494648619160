import React from "react";

export default class CristataRedirectPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.location.href = "https://cristata.app/troop-370";
  }

  render() {
    return (
      <>
        Redirecting to{" "}
        <a href={"https://cristata.app/troop-370"}>
          https://cristata.app/troop-370
        </a>
        ...
      </>
    );
  }
}
