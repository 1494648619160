import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import theme from '../../utils/theme'

export default class TableHeaderCell extends React.Component {
  render() {
    return <div css={css`
      font-family: ${theme.headlineFont};
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.5px;
      padding: 0 0.5rem;
      &:first-of-type {
        padding: 0 0.5rem 0 0;
      }
      line-height: 16px;
      transition: color 200ms ease 0s;
      color: ${theme.color.lowEmphasis};
      width: 15%;
      & + & {
        width: 15%;
      }
      & + & + & {
        width: 30%;
      }
      & + & + & + & {
        width: 25%;
      }
      & + & + & + & + & {
        width: 15%;
      }
    `} role='columnheader'>{this.props.children}</div>
  }
}